import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const JeMeConnecte = () => {
    const [pageState, setPageState] = useState("greet");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [smsCode, setSmsCode] = useState("");
    const [countryCode, setCountryCode] = useState("+33");  // Default to +33
    const navigate = useNavigate();

    // Prefill the phone number if it exists in localStorage
    // useEffect(() => {
    //     const storedPhoneNumber = localStorage.getItem('userPhoneNumber');
    //     if (storedPhoneNumber) {
    //         setPhoneNumber(storedPhoneNumber);
    //     }
    // }, []);

    const
        handleSendSms = async () => {
        const fullPhoneNumber = `${countryCode}${phoneNumber.slice(1)}`;  // Combine country code with phone number
        try {
            const response = await fetch(`https://api.holiyou.life/API/user/sms/send/${fullPhoneNumber}`, {
                method: 'POST'
            });

            console.log({ response });

            if (response.ok) {
                toast.success("SMS envoyé avec succès!");
                setPageState("verify");
            } else {
                toast.error("L'envoi de SMS a échoué. Veuillez réessayer.");
            }
        } catch (error) {
            console.log(error);
            toast.error("Une erreur s'est produite lors de l'envoi de SMS.");
        }
    };

    const handleVerifyCode = async () => {
        const fullPhoneNumber = `${countryCode}${phoneNumber.slice(1)}`;  // Combine country code with phone number
        try {
            const response = await fetch('https://api.holiyou.life/API/user/sms/authentify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phone: fullPhoneNumber, code: smsCode })
            });

            if (response.ok) {
                const data = await response.json();
                if (data) {
                    // Store user ID and name in localStorage
                    localStorage.setItem('userId', data.user.token.id);
                    localStorage.setItem('userPhoneNumber', data.user.phoneNumber);
                    localStorage.setItem('userFirstName', data.user.firstname);

                    toast.success("Code vérifié avec succès!");

                    navigate('/mon-espace/prendre-soin-de-moi');
                } else {
                    toast.error("Code incorrect. Veuillez réessayer.");
                }
            } else {
                toast.error("La vérification du code a échoué. Veuillez réessayer.");
            }
        } catch (error) {
            toast.error("Une erreur s'est produite lors de la vérification du code.");
        }
    };

    const renderGreetPage = () => (
        <div style={styles.container}>
            <h2 style={styles.title}>Bienvenue!</h2>
            <p style={styles.subtitle}>Veuillez continuer pour vous connecter</p>
            <button onClick={() => setPageState("login")} style={styles.button}>
                Continuer à se connecter
            </button>
        </div>
    );

    const renderLoginPage = () => (
        <div style={styles.container}>
            <h2 style={styles.title}>Connexion</h2>
            <p style={styles.subtitle}>Avez-vous un compte? Entrez votre numéro de téléphone pour vous connecter.</p>
            <div style={styles.inputContainer}>
                <select
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    style={styles.countryCodeSelect}
                >
                    <option value="+33">+33 (France)</option>
                    <option value="+41">+41 (Suisse)</option>
                    <option value="+34">+34 (Espagne)</option>
                </select>
                <input
                    type="text"
                    placeholder="0600000000"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    style={styles.input}
                />
            </div>
            <button onClick={handleSendSms} style={styles.button}>
                Envoyer le SMS
            </button>
        </div>
    );

    const renderVerifyPage = () => (
        <div style={styles.container}>
            <h2 style={styles.title}>Vérification</h2>
            <p style={styles.subtitle}>Veuillez entrer le code que vous avez reçu par SMS.</p>
            <input
                type="text"
                placeholder="Entrez le code SMS"
                value={smsCode}
                onChange={(e) => setSmsCode(e.target.value)}
                style={styles.input}
            />
            <button onClick={handleVerifyCode} style={styles.button}>
                Vérifier le code
            </button>
        </div>
    );

    return (
        <div style={styles.main}>
            {pageState === "greet" && renderGreetPage()}
            {pageState === "login" && renderLoginPage()}
            {pageState === "verify" && renderVerifyPage()}
            <ToastContainer />
        </div>
    );
};

const styles = {
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f6e1c8",
        fontFamily: "'Poppins', sans-serif",
    },
    container: {
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        padding: "30px",
        maxWidth: "400px",
        textAlign: "center",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    },
    title: {
        fontSize: "24px",
        fontWeight: "bold",
        marginBottom: "10px",
        color: "#333333",
    },
    subtitle: {
        fontSize: "16px",
        marginBottom: "20px",
        color: "#555555",
    },
    button: {
        backgroundColor: "#512E0B",
        color: "#FFFFFF",
        padding: "10px 20px",
        borderRadius: "5px",
        border: "none",
        cursor: "pointer",
        fontSize: "16px",
        marginTop: "20px",
        width: "100%",
        transition: "background-color 0.3s ease",
    },
    inputContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    countryCodeSelect: {
        padding: "10px",
        borderRadius: "5px 0 0 5px",
        border: "1px solid #CCCCCC",
        fontSize: "16px",
        backgroundColor: "#f9f9f9",
        marginRight: "-1px",
    },
    input: {
        padding: "10px",
        borderRadius: "0 5px 5px 0",
        width: "100%",
        border: "1px solid #CCCCCC",
        fontSize: "16px",
        textAlign: "center",
    }
};

export default JeMeConnecte;
