import './Presentation.css';
import React from 'react';
import ReactPlayer from 'react-player'

const Presentation = () => {
    const [play, setPlay] = React.useState(false)

    return (
        <div id="presentation">
            <div className='generalBlock presentationBlock' style={{ paddingBottom: 75 }}>
                <div className='presBlockDroite'>
                    <ReactPlayer
                        url={"https://holiyou.life/website-video/Holiyou-Interview_Eug%C3%A9nie-Version_Courte_v1.mp4"}
                        pip={false}
                        light={process.env.PUBLIC_URL + '/assets/Minia-Eugenie.webp'}
                        controls={true}
                        className='logoHoliyou cliquable'
                        playing={play}
                        onClickPreview={() => setPlay(true)}
                        playIcon={<div></div>}
                        config={{ file: {
                            attributes: {
                              controlsList: 'nodownload',
                              onContextMenu: e => e.preventDefault()
                            }
                          }}}
                    />
                </div>
                <div className='presBlockGauche' style={{ position: "relative", height: "100%" }}>
                    <div className='titrePresentation'>
                        HoliYou, l'assistant
                        digital personnalisé
                        pour la PMA
                    </div>
                    <div style={{display:"flex",alignItems:"center"}}>
                        <div>
                            <img className='logoHoliyou2' alt='Logo Holiyou' src={process.env.PUBLIC_URL + '/assets/HoliYou_Logo.webp'} />
                        </div>
                        <div className='presText presText1'>
                            Repenser l'accompagnement pour améliorer votre parcours de Procréation Médicalement Assistée au quotidien.
                        </div>
                    </div>
                    <div className='presText launchPresText'>
                        <div className='lancement'>
                            BIENTÔT DISPONIBLE SUR
                        </div>
                        <div id="IMG STORE">
                            <div style={{ width: "40%", display: "inline-block" }}>
                                <a id="download_ios" href="#presentation">
                                    <img style={{ width: "100%" }} alt='Download on app store' src={process.env.PUBLIC_URL + '/assets/app store.webp'} />
                                </a>
                            </div>
                            <div style={{ width: "40%", display: "inline-block", marginLeft: 15 }}>
                                <a id="download_android" href="#presentation">
                                    <img style={{ width: "100%" }} alt='Download on google playstore' src={process.env.PUBLIC_URL + '/assets/googleplay store.webp'} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Presentation;
