import {
  CULTURE_EMBRYONNAIRE,
  DECLENCHEMENT,
  IMPLANTATION,
  PONCTION,
  PREPARATION,
  REPOS,
  STIMULATION,
  SYNCHRONISATION,
  TRANSFERT
} from "./constants";

export const Yoga = [
  {
    title: 'Yoga du Visage I',
    duration: 14,
    phase: SYNCHRONISATION,
    uri: "https://holiyou.life/videos/08-yogavisage/Yoga%20du%20Visage%201%20H264%203Mbps.mp4",
    preview: require("../previews/YogaVisageShort.png"),
  },
  {
    title: 'Yoga du Visage II',
    duration: 16,
    phase: SYNCHRONISATION,
    uri: "https://holiyou.life/videos/08-yogavisage/Yoga%20du%20Visage%202%20H264%203Mbps.mp4",
    preview: require("../previews/YogaVisageLong.png"),
  },
  {
    title: 'Shavasana',
    duration: 20,
    phase: SYNCHRONISATION,
    uri: "https://holiyou.life/videos/07-Shawasana/Shawasana%20H264%203Mbps.mp4",
    preview: require("../previews/Shavasana.png"),
  },

  {
    title: 'Yoga Recovery',
    phase: REPOS,
    duration: 59,
    uri: "https://holiyou.life/videos/06-recovery/Recovery%20-%20Yoga%2060min%20H264%203Mbps.mp4",
    preview: require("../previews/YogaRecovery.png")
  },
  {
    title: 'Yoga du Visage I',
    phase: REPOS,
    duration: 14,
    uri: "https://holiyou.life/videos/08-yogavisage/Yoga%20du%20Visage%201%20H264%203Mbps.mp4",
    preview: require("../previews/YogaVisageShort.png"),
  },
  {
    title: 'Yoga du Visage II',
    phase: REPOS,
    duration: 16,
    uri: "https://holiyou.life/videos/08-yogavisage/Yoga%20du%20Visage%202%20H264%203Mbps.mp4",
    preview: require("../previews/YogaVisageLong.png"),
  },
  {
    title: 'Shavasana',
    phase: REPOS,
    duration: 20,
    uri: "https://holiyou.life/videos/07-Shawasana/Shawasana%20H264%203Mbps.mp4",
    preview: require("../previews/Shavasana.png"),
  },

  {
    title: 'Yoga Fertilité I',
    phase: STIMULATION,
    duration: 28,
    uri: "https://holiyou.life/videos/02-Stimulation/Stimulation%20-%20Yoga%2020min%20H264%203Mbps.mp4",
    preview: require("../previews/YogaFertiliteShort.png")
  },
  {
    title: 'Yoga Fertilité II',
    phase: STIMULATION,
    duration: 67,
    uri: "https://holiyou.life/videos/02-Stimulation/Stimulation%20-%20Yoga%2060min%20H264%203Mbps.mp4",
    preview: require("../previews/YogaFertiliteLong.png")
  },

  {
    title: 'Yoga Pré-Ponction I',
    phase: DECLENCHEMENT,
    duration: 23,
    uri: "https://holiyou.life/videos/03-Pr%c3%a9ponction/Pre%cc%81%20Ponction%20-%20Yoga%2020min%20H264%203Mbps.mp4",
    preview: require("../previews/YogaPrePonctionShort.png")
  },
  {
    title: 'Yoga Pré-Ponction II',
    phase: DECLENCHEMENT,
    duration: 59,
    uri: "https://holiyou.life/videos/03-Pr%c3%a9ponction/Pre%cc%81%20Ponction%20-%20Yoga%2060min%20H264%203Mbps.mp4",
    preview: require("../previews/YogaPrePonctionLong.png")
  },
  {
    title: 'Yoga du Visage I',
    phase: DECLENCHEMENT,
    duration: 14,
    uri: "https://holiyou.life/videos/08-yogavisage/Yoga%20du%20Visage%201%20H264%203Mbps.mp4",
    preview: require("../previews/YogaVisageShort.png"),
  },
  {
    title: 'Yoga du Visage II',
    phase: DECLENCHEMENT,
    duration: 16,
    uri: "https://holiyou.life/videos/08-yogavisage/Yoga%20du%20Visage%202%20H264%203Mbps.mp4",
    preview: require("../previews/YogaVisageLong.png"),
  },
  {
    title: 'Shavasana',
    phase: DECLENCHEMENT,
    duration: 20,
    uri: "https://holiyou.life/videos/07-Shawasana/Shawasana%20H264%203Mbps.mp4",
    preview: require("../previews/Shavasana.png"),
  },

  {
    title: 'Yoga Pré-Ponction I',
    phase: PONCTION,
    duration: 23,
    uri: "https://holiyou.life/videos/03-Pr%c3%a9ponction/Pre%cc%81%20Ponction%20-%20Yoga%2020min%20H264%203Mbps.mp4",
    preview: require("../previews/YogaPrePonctionShort.png")
  },
  {
    title: 'Yoga Pré-Ponction II',
    phase: PONCTION,
    duration: 59,
    uri: "https://holiyou.life/videos/03-Pr%c3%a9ponction/Pre%cc%81%20Ponction%20-%20Yoga%2060min%20H264%203Mbps.mp4",
    preview: require("../previews/YogaPrePonctionLong.png")
  },
  {
    title: 'Yoga du Visage I',
    phase: PONCTION,
    duration: 14,
    uri: "https://holiyou.life/videos/08-yogavisage/Yoga%20du%20Visage%201%20H264%203Mbps.mp4",
    preview: require("../previews/YogaVisageShort.png"),
  },
  {
    title: 'Yoga du Visage II',
    phase: PONCTION,
    duration: 16,
    uri: "https://holiyou.life/videos/08-yogavisage/Yoga%20du%20Visage%202%20H264%203Mbps.mp4",
    preview: require("../previews/YogaVisageLong.png"),
  },
  {
    title: 'Shavasana',
    phase: PONCTION,
    duration: 20,
    uri: "https://holiyou.life/videos/07-Shawasana/Shawasana%20H264%203Mbps.mp4",
    preview: require("../previews/Shavasana.png"),
  },

  {
    title: 'Yoga Pré-Transfert I',
    phase: CULTURE_EMBRYONNAIRE,
    duration: 28,
    uri: "https://holiyou.life/videos/04-Pr%c3%a9transfert/Pre%cc%81%20Transfert%20-%20Yoga%2020min%20H264%203Mbps.mp4",
    preview: require("../previews/YogaPostPonctionShort.png")
  },
  {
    title: 'Yoga Pré-Transfert II',
    phase: CULTURE_EMBRYONNAIRE,
    duration: 65,
    uri: "https://holiyou.life/videos/04-Pr%c3%a9transfert/Pre%cc%81%20Transfert%20-%20Yoga%2060min%20H264%203Mbps.mp4",
    preview: require("../previews/YogaPostPonctionLong.png")
  },
  {
    title: 'Yoga du Visage I',
    phase: CULTURE_EMBRYONNAIRE,
    duration: 14,
    uri: "https://holiyou.life/videos/08-yogavisage/Yoga%20du%20Visage%201%20H264%203Mbps.mp4",
    preview: require("../previews/YogaVisageShort.png"),
  },
  {
    title: 'Yoga du Visage II',
    phase: CULTURE_EMBRYONNAIRE,
    duration: 16,
    uri: "https://holiyou.life/videos/08-yogavisage/Yoga%20du%20Visage%202%20H264%203Mbps.mp4",
    preview: require("../previews/YogaVisageLong.png"),
  },
  {
    title: 'Shavasana',
    phase: CULTURE_EMBRYONNAIRE,
    duration: 20,
    uri: "https://holiyou.life/videos/07-Shawasana/Shawasana%20H264%203Mbps.mp4",
    preview: require("../previews/Shavasana.png"),
  },
  {
    title: 'Yoga Recovery',
    phase: CULTURE_EMBRYONNAIRE,
    duration: 59,
    uri: "https://holiyou.life/videos/06-recovery/Recovery%20-%20Yoga%2060min%20H264%203Mbps.mp4",
    preview: require("../previews/YogaRecovery.png")
  },

  {
    title: 'Shavasana',
    phase: TRANSFERT,
    duration: 20,
    uri: "https://holiyou.life/videos/07-Shawasana/Shawasana%20H264%203Mbps.mp4",
    preview: require("../previews/Shavasana.png"),
  },

  {
    title: 'Yoga Implantation I',
    phase: IMPLANTATION,
    duration: 24,
    uri: "https://holiyou.life/videos/05-Posttransfert/Post%20Transfert%20-%20Yoga%2020min%20H264%203Mbps.mp4",
    preview: require("../previews/YogaImplantationShort.png")
  },
  {
    title: 'Yoga Implantation II',
    phase: IMPLANTATION,
    duration: 59,
    uri: "https://holiyou.life/videos/05-Posttransfert/Post%20Transfert%20-%20Yoga%2060min%20H264%203Mbps.mp4",
    preview: require("../previews/YogaImplantationLong.png")
  },
  {
    title: 'Yoga du Visage I',
    phase: IMPLANTATION,
    duration: 14,
    uri: "https://holiyou.life/videos/08-yogavisage/Yoga%20du%20Visage%201%20H264%203Mbps.mp4",
    preview: require("../previews/YogaVisageShort.png"),
  },
  {
    title: 'Yoga du Visage II',
    phase: IMPLANTATION,
    duration: 16,
    uri: "https://holiyou.life/videos/08-yogavisage/Yoga%20du%20Visage%202%20H264%203Mbps.mp4",
    preview: require("../previews/YogaVisageLong.png"),
  },
  {
    title: 'Shavasana',
    phase: IMPLANTATION,
    duration: 20,
    uri: "https://holiyou.life/videos/07-Shawasana/Shawasana%20H264%203Mbps.mp4",
    preview: require("../previews/Shavasana.png"),
  },
  {
    title: 'Yoga Recovery',
    phase: IMPLANTATION,
    duration: 59,
    uri: "https://holiyou.life/videos/06-recovery/Recovery%20-%20Yoga%2060min%20H264%203Mbps.mp4",
    preview: require("../previews/YogaRecovery.png")
  },
]

export const YogaTextes = [
  {
    phase: PREPARATION,
    content:
      "La pratique régulière du yoga, ainsi que du Shavasana, favorise la détente, améliore le bien-être global et diminue le stress. L'intégration de ces exercices tout au long de votre parcours de PMA favorise une relaxation profonde, apaisant à la fois le corps et l'esprit pour un meilleur équilibre émotionnel.\n" +
      "\n" +
      "Que ce soit seule ou en duo, elles vous offriront un précieux moment de bien-être."
  },
  {
    phase: SYNCHRONISATION,
    content:
      "La pratique régulière du yoga, ainsi que du Shavasana, favorise la détente, améliore le bien-être global et diminue le stress. L'intégration de ces exercices tout au long de votre parcours de PMA favorise une relaxation profonde, apaisant à la fois le corps et l'esprit pour un meilleur équilibre émotionnel.\n" +
      "\n" +
      "Que ce soit seule ou en duo, elles vous offriront un précieux moment de bien-être."
  },
  {
    phase: REPOS,
    content:
      "La pratique régulière du yoga, ainsi que du Shavasana, favorise la détente, améliore le bien-être global et diminue le stress. L'intégration de ces exercices tout au long de votre parcours de PMA favorise une relaxation profonde, apaisant à la fois le corps et l'esprit pour un meilleur équilibre émotionnel.\n" +
      "\n" +
      "Que ce soit seule ou en duo, elles vous offriront un précieux moment de bien-être."
  },
  {
    phase: STIMULATION,
    content:
      "Ces exercices de yoga que nous vous proposons stimulent le système endocrinien, favorisant ainsi l'équilibre hormonal et la fertilité naturelle. ",
  },
  {
    phase: DECLENCHEMENT,
    content:
      "Nous vous proposons du yoga doux et reposant pour mieux accueillir ces étapes clefs de votre parcours: yoga, yoga du visage et shavasana vous aident à vous apaiser et faire descendre le niveau de stress.\n" +
      "A pratiquer sans modération!"
  },
  {
    phase: CULTURE_EMBRYONNAIRE,
    content:
      "Ces pratiques douces favorisent la détente, réduisent le stress et créent un environnement corporel propice à la réussite du transfert.\n" +
      " \n" +
      "En combinant la méditation au yoga, vous préparez votre corps et votre esprit à accueillir l'embryon."
  },
  {
    phase: TRANSFERT,
    content:
      "Intégrez le yoga Shavasana tout au long de votre parcours de PMA favorise la relaxation profonde, apaisant ainsi le corps et l'esprit pour un meilleur équilibre émotionnel.\n" +
      "A pratiquer seul.e ou en duo pour plus de bénéfices !"
  },
  {
    phase: IMPLANTATION,
    content:
      "Ce yoga implantation encourage la circulation sanguine vers l'utérus, ce qui peut améliorer la réceptivité de la muqueuse utérine à l'embryon.\n" +
      " \n" +
      "En combinant la méditation au yoga, vous préparez votre corps et votre esprit à accueillir l'embryon."
  },
]
