import './Cookie.css';
import React from 'react';

const Cookie = (props) => {
    const { isMobile } = props;
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='generalBlock' id='pol-conf'>
            <div className='cookieBlock'>
                <div className='titreGlobal textViolet titreCookie'>
                    {"Politique de Cookies pour l'Application et le Site Web HOLIYOU".toUpperCase()}
                </div>

                <div className='textViolet sousTitreCookie'>
                    {"1. Introduction".toUpperCase()}
                </div>
                <div>
                    <p>
                        L'application HoliYou et son site web <a href='https://www.holiyou.life/' target={"_blank"} rel="noopener noreferrer">https://www.holiyou.life/</a> utilisent des cookies pour améliorer votre expérience utilisateur.
                        Cette politique de cookies explique ce que sont les cookies, comment nous les utilisons, et comment vous pouvez les gérer.
                    </p>
                </div>

                <div className='textViolet sousTitreCookie'>
                    {"2. Qu'est-ce qu'un Cookie ?".toUpperCase()}
                </div>
                <div>
                    <p>
                        Un cookie est un petit fichier texte stocké sur votre ordinateur ou appareil mobile par l'application lorsque vous l'utilisez.
                        Les cookies permettent à l'application de se souvenir de vos actions et préférences.
                    </p>
                </div>

                <div className='textViolet sousTitreCookie'>
                    {"3. Types de Cookies Utilisés".toUpperCase()}
                </div>
                <div>
                    <p>
                        <span className='personnelCookieSpan'>Cookies Essentiels</span> : Ces cookies sont nécessaires au bon fonctionnement de l'application et vous permettent d'accéder à ses fonctionnalités de base.
                    </p>
                    <p>
                        <span className='personnelCookieSpan'>Cookies de Performance</span> : Ces cookies collectent des informations sur la manière dont vous utilisez l'application, nous aidant ainsi à améliorer ses performances.
                    </p>
                    <p>
                        <span className='personnelCookieSpan'>Cookies Fonctionnels</span> : Ces cookies permettent à l'application de se souvenir de vos préférences et de personnaliser votre expérience.
                    </p>
                </div>

                <div className='textViolet sousTitreCookie'>
                    {"4. Consentement à l'Utilisation des Cookies".toUpperCase()}
                </div>
                <div>
                    <p>
                        En utilisant notre application et son site web, vous consentez à l'utilisation des cookies conformément à cette Politique de Cookies.
                        Vous pouvez gérer vos préférences en matière de cookies à tout moment via les paramètres de votre appareil.
                    </p>
                </div>

                <div className='textViolet sousTitreCookie'>
                    {"5. Gestion des Cookies".toUpperCase()}
                </div>
                <div>
                    <p>
                        Vous pouvez désactiver ou supprimer les cookies en ajustant les paramètres de votre appareil mobile.
                        Pour en savoir plus, consultez le menu d'aide de votre appareil mobile.
                    </p>
                </div>

                <div className='textViolet sousTitreCookie'>
                    {"6. Modification de la Politique de Cookies".toUpperCase()}
                </div>
                <div>
                    <p>
                        Nous nous réservons le droit de modifier cette Politique de Cookies à tout moment. Les modifications seront publiées dans l'application.
                    </p>
                </div>

                <div className='textViolet sousTitreCookie'>
                    {"7. Contact".toUpperCase()}
                </div>
                <div>
                    <p>
                        Si vous avez des questions concernant notre Politique de Cookies, veuillez nous contacter à <a href='mailto:bonjour@holiyou.life' target={"_blank"} rel="noopener noreferrer">bonjour@holiyou.life</a>.
                        Le responsable du traitement est WHITECOATS, société par actions à responsabilité limitée,
                        immatriculée en Suisse sous le numéro CH-550.1.231.978-2 dont le siège social se trouve rue de Langallerie, 7 – 1003 Lausanne (Suisse)
                        exploitant le site internet <a href='https://www.holiyou.life' target={"_blank"} rel="noopener noreferrer">www.holiyou.life</a> et l’Application HoliYou.

                    </p>
                </div>
            </div>
        </div>
    )
}

export default Cookie;
