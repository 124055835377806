import './Comite.css';
import React from 'react';

const Comite = (props) => {
    const { updatePos } = props;

    return (
        <div id="comite" onMouseEnter={() => updatePos('comite')} className='section comiteSection' >
            <div className='generalBlock comiteBlock'>
                <div className='titreGlobal textViolet' id="expertText">
                    Nos experts
                </div>
                <div className='middleText middleText2Comite'>
                    Confrontées aux problèmes de fertilité dans leurs métiers respectifs, elles développent en exclusivité les
                    programmes et contenus self-care disponible sur notre plateforme HoliYou.
                </div>
                <div className='imageComiteContainer'>
                    <div className='imageComiteWrap'>
                        <div className='imageComite'>
                            <img style={{ width: "100%" }} alt='Dr Valérie Espinasse - Micronutritioniste' src={process.env.PUBLIC_URL + '/assets/comite/valerie-espinasse.webp'} />
                        </div>
                    </div>
                    <div className='imageComiteWrap'>
                        <div className='imageComite'>
                            <img style={{ width: "100%" }} alt='Hester Wolfensberger - Naturopathe certifiée' src={process.env.PUBLIC_URL + '/assets/comite/hester-wolfensberger.webp'} />
                        </div>
                    </div>
                    <div className='imageComiteWrap'>
                        <div className='imageComite'>
                            <img style={{ width: "100%" }} alt='Amélie Guiraud - Professeur de Yoga' src={process.env.PUBLIC_URL + '/assets/comite/amelie-guiraud.webp'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Comite;
