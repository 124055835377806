import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './Elements/Header';
import Presentation from './Elements/Presentation';
import Mission from './Elements/Mission';
import Solutions from './Elements/Solutions';
import Comite from './Elements/Comite';
import Testez from './Elements/Testez';
import Temoignage from './Elements/Temoignage';
import Contactez from './Elements/Contactez';
import Footer from './Elements/Footer';
import Soutient from './Elements/Soutient';

import Politique from './Elements/politiqueConf/Politique';
import CGU from './Elements/CGU/Cgu';
import Cookies from './Elements/Cookies/Cookie';

import "./fonts/Poppins-Regular.ttf";
import "./fonts/Poppins-Bold.ttf";
import JeMeConnecte from "./Elements/MonEspace/JeMeConnecte";
import PrendreSoinDeMoi from "./Elements/MonEspace/PrendreSoinDeMoi";

function App() {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 1000);
  const [popupText, setPopupText] = React.useState("");
  const [popupBool, setPopupBool] = React.useState(false);
  const [popupTest, setPopupTest] = React.useState(false);
  const [popupTestFired, setPopupTestFired] = React.useState(false);

  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 1000);
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    if (!popupTestFired) {
      setTimeout(() => {
        setPopupTestFired(true);
        setPopupTest(true);
      }, 4000);
    }
  }, [popupTestFired]);

  React.useEffect(() => {
    if (popupText !== "") {
      setPopupBool(true);
      setTimeout(() => {
        setPopupBool(false);
        setPopupText("");
      }, 5000);
    }
  }, [popupText]);

  function popupTestez() {
    return (
        <div style={{ width: "100%", height: window.innerHeight, position: "fixed", top: 0, left: 0, backgroundColor: "rgba(0,0,0,0.5)", zIndex: 10 }}>
          <div className='verticalHorizontalCenter validationModal2'>
            <div onClick={() => setPopupTest(false)} className='cliquable buttonExitPopup'>
              <img src={process.env.PUBLIC_URL + '/assets/guillemet.webp'} alt={"guillemet"} />
              <div style={{ verticalAlign: "top" }}>
                Retour
              </div>
            </div>
            <div className='textViolet popupTestText' >
              Inscrivez-vous dès maintenant pour tester l'app' en avant-première et bénéficiez de 1 mois d'accompagnement gratuit
            </div>
            <a onClick={() => setPopupTest(false)} style={{ textDecoration: "none" }} href='#testez'>
              <div id='formular_button' className='cliquable boutonInscription' style={{ marginTop: 60 }}>Je m'inscris</div>
            </a>
          </div>
        </div>
    )
  }

  function popupSend() {
    return (
        <div style={{ width: "100%", height: window.innerHeight, position: "fixed", top: 0, left: 0, backgroundColor: "rgba(0,0,0,0.5)", zIndex: 10 }}>
          <div className='verticalHorizontalCenter validationModal'>
            <div className='textViolet' >{popupText}</div>
          </div>
        </div>
    )
  }

  function MainPage() {
    return (
        <div className="App mainSection">
          {popupBool ? popupSend() : null}
          {popupTest ? popupTestez() : null}
          <Header isMobile={isMobile}/>
            <Presentation/>
            <Mission updatePos={() => {}}/>
            <Solutions updatePos={() => {}}/>
            <Comite updatePos={() => {}}/>
            <Testez updatePos={() => {}} setPopup={setPopupText}/>
            <Temoignage updatePos={() => {}}/>
            <Soutient/>
            <Contactez/>
          <Footer/>
        </div>
    )
  }

  return (
      <Router>
          <Routes>
            <Route path="/" element={<MainPage/>}/>
            <Route path="/pol-conf" element={<Politique isMobile={isMobile} />} />
            <Route path="/cgu" element={<CGU isMobile={isMobile} />} />
            <Route path="/cookies" element={<Cookies isMobile={isMobile} />} />

            <Route path="/mon-espace/se-connecter" element={<JeMeConnecte />} />
            <Route path="/mon-espace/prendre-soin-de-moi" element={<PrendreSoinDeMoi />} />

          </Routes>
      </Router>
  );
}

export default App;
