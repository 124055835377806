import React from 'react';
import "./Soutient.css"

const Soutient = () => {

    return (
        <div id="soutient" className='section' >
            <div className='generalBlock' style={{paddingBottom: 40}}>
                <div className='titreGlobal textViolet titreSolution' id="soutientText">
                    Ils nous soutiennent
                </div>
                <div className="soutientWrapper">
                    <div className='soutientImgContainer2'>
                        <a href="https://www.hug.ch/medecine-reproduction-endocrinologie-gynecologique" target="_blank"
                           rel="noopener noreferrer">
                            <img className="soutientImg2" src={process.env.PUBLIC_URL + '/assets/logo hug.webp'}
                                 alt={"hug"}/>
                        </a>
                    </div>
                    <div className='soutientImgContainer2'>
                        <a href="https://natuvitro.com/fr" target="_blank" rel="noopener noreferrer">
                            <img className="soutientImg2" src={process.env.PUBLIC_URL + '/assets/logo natuvitro.webp'}
                                 alt={"natuvitro"}/>
                        </a>
                    </div>
                    <div className='soutientImgContainer'>
                        <a href="https://www.science2market.ch/" target="_blank" rel="noopener noreferrer">
                            <img className="soutientImg"
                                 src={process.env.PUBLIC_URL + '/assets/logo science2market.webp'}
                                 alt={"science2market"}/>
                        </a>
                    </div>
                </div>
                <div className="soutientWrapper soutientWrapperMargin">
                    <div className='soutientImgContainer'>
                        <a href="https://genilem.ch/" target="_blank" rel="noopener noreferrer">
                            <img className="soutientImg" src={process.env.PUBLIC_URL + '/assets/logo-genilem.webp'}
                                 alt={"genilem"}/>
                        </a>
                    </div>
                    <div className='soutientImgContainer'>
                        <a href="https://www.fondation-fit.ch/fr/" target="_blank" rel="noopener noreferrer">
                            <img className="soutientImg" src={process.env.PUBLIC_URL + '/assets/logo fit.webp'}
                                 alt={"fit"}/>
                        </a>
                    </div>
                    <div className='soutientImgContainer'>
                        <a href="https://www.biopole.ch/start-ups/vanguard/" target="_blank" rel="noopener noreferrer">
                            <img className="soutientImg" src={process.env.PUBLIC_URL + '/assets/logo biopole.webp'}
                                 alt={"biopole"}/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Soutient;
