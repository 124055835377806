import './Header.css';
import '../App.css'
import React from 'react';
import {useNavigate} from "react-router-dom";

const Header = (props) => {
    const { pos, isMobile } = props;
    const [mobileMenu, setMobileMenu] = React.useState(false)

    const navigate = useNavigate();

    if (isMobile) {
        return (
            <div className='colorDarkBrown headerBlockMobile' >
                {mobileMenu?<div className='mobileMenuWrapper'>
                    <div className='wrapperDecal'>
                        <div onClick={() => setMobileMenu(false)} className='imgCloseWrapper cliquable'>
                            <img style={{ width: "100%" }} alt='Burger menu' src={process.env.PUBLIC_URL + '/assets/fermer menu burger mobile.webp'} />
                        </div>
                        <a onClick={() => setMobileMenu(false)} className="MobileMenuText" href="#missionText">Notre mission</a><br />
                        <a onClick={() => setMobileMenu(false)} className="MobileMenuText" href="#solutionText">Nos solutions</a><br />
                        <a onClick={() => setMobileMenu(false)} className="MobileMenuText" href="#expertText">Nos experts</a><br />
                        <a onClick={() => setMobileMenu(false)} className="MobileMenuText" href="#testezText">Testez HoliYou !</a><br />
                        <a onClick={() => setMobileMenu(false)} className="MobileMenuText" href="#temoignageText">Vos Témoignages</a><br />
                        <a onClick={() => setMobileMenu(false)} className="MobileMenuText" href="#soutientText">Ils nous soutiennent</a>
                    </div>
                </div>:null}
                <div style={{ height: 100, display: 'flex' }}>
                    <div style={{ display: "flex", width: "65%", height: 75, textAlign: 'center', position: "relative" }}>
                        <div onClick={() => setMobileMenu(true)} className='imgBurger cliquable'>
                            <img style={{ width: "100%" }} alt='Burger menu' src={process.env.PUBLIC_URL + '/assets/menu burger mobile.webp'} />
                        </div>
                    </div>
                    <div style={{ height: 75, width: '35%', textAlign: 'center', position: "relative" }}>
                        <div className='headerElem' style={{ width: "40%", float: "right", marginRight: "15%" }}>
                            <a href='https://www.linkedin.com/company/89638267/admin/' target={"_blank"} rel="noopener noreferrer">
                                <img style={{ width: "100%" }} alt='Linkedin' src={process.env.PUBLIC_URL + '/assets/linkedin.webp'} />
                            </a>
                            <div style={{ padding: 5 }} />
                            <a href='https://www.instagram.com/holiyou.fertilite/' target={"_blank"} rel="noopener noreferrer">
                                <img style={{ width: "100%" }} alt='Instagram' src={process.env.PUBLIC_URL + '/assets/instagram.webp'} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className='colorDarkBrown headerBlock' >
                <div className='generalBlock' style={{height: 100, display: 'flex'}}>
                    <div style={{
                        display: "flex",
                        width: "80%",
                        height: "100%",
                        textAlign: 'center',
                        position: "relative"
                    }}>
                        <div className='headerElem'>
                            <a className={pos === "mission" ? "selected" : 'notSelected'} href="#mission">Notre
                                mission</a>
                            <a className={pos === "solution" ? "selected" : 'notSelected'} href="#solution">Nos
                                solutions</a>
                            <a className={pos === "comite" ? "selected" : 'notSelected'} href="#comite">Nos experts</a>
                            <a className={pos === "testez" ? "selected" : 'notSelected'} href="#testez">Testez HoliYou
                                !</a>
                            <a className={pos === "temoignage" ? "selected" : 'notSelected'} href="#temoignage">Vos
                                Témoignages</a>
                            <a className={pos === "soutient" ? "selected" : 'notSelected'} href="#soutient">Ils nous
                                soutiennent</a>
                        </div>
                    </div>
                    <div style={{height: "100%", width: '20%', textAlign: 'center', position: "relative"}}>
                        <div className='headerElem' style={{width: "30%", float: "right"}}>
                            <a href='https://www.linkedin.com/company/89638267/admin/' target={"_blank"}
                               rel="noopener noreferrer">
                                <img style={{width: "100%"}} alt='Linkedin'
                                     src={process.env.PUBLIC_URL + '/assets/linkedin.webp'}/>
                            </a>
                            <div style={{padding: 5}}/>
                            <a href='https://www.instagram.com/holiyou.fertilite/' target={"_blank"}
                               rel="noopener noreferrer">
                                <img style={{width: "100%"}} alt='Instagram'
                                     src={process.env.PUBLIC_URL + '/assets/instagram.webp'}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;
