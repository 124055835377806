import './Cgu.css';
import React from 'react';

const Cgu = (props) => {
    const { isMobile } = props;
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='generalBlock' id='cgu'>
            <div className='politiqueBlock' style={{ paddingBottom: 30 }}>
                <div className='titreGlobal textViolet titrePolitique'>
                    Conditions générales d’utilisation HoliYou
                </div>
                <div className='titreSubCGU textViolet'>
                    Quatrième version 11.12.23 (version sans abonnement)
                </div>
                <div>
                    <p>
                        Nous vous souhaitons la bienvenue sur ce site dédié à HoliYou et ses services.
                    </p>
                    <p>
                        HoliYou a été conçue en 2023, pour répondre aux besoins des personnes en parcours de procréation médicalement assistée.
                        Notre mission est de concevoir un outil digital qui permette d’améliorer le quotidien des patients en parcours de PMA, de soulager leurs maux,
                        et de les soutenir à travers une approche unique et transdisciplinaire.
                        Cette application est le fruit d’une collaboration avec des médecins spécialistes de la Procréation Médicalement Assistée, des gynécologues,
                        des psychologues, des sexologues et des thérapeutes en fertilité.
                    </p>
                    <p>
                        Nous sommes animés par le désir d’améliorer la qualité de vie de ceux qui vivent un parcours médical
                        et qui méritent d’être entourés et accompagnés dans leurs projets de vie.
                    </p>
                    <p>
                        Nous vous invitons à lire attentivement les présentes conditions d’utilisation qui régissent la navigation sur ce Site (ci-après les « Conditions d’Utilisation »).
                        En utilisant le Site vous acceptez sans réserve les présentes Conditions d’Utilisation.
                    </p>
                    <p>
                        Pour toute demande relative à votre utilisation du Site, vous pouvez nous contacter à : <a href='mailto:bonjour@holiyou.life' target={"_blank"} rel="noopener noreferrer">bonjour@holiyou.life</a>
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    Introduction
                </div>
                <div>
                    <p>
                        HoliYou est une application et un site développés par Whitecoats. HoliYou et Whitecoats ne prennent aucune responsabilité légale ou
                        médicale quant à la manière dont vous interprétez ou utilisez les informations fournies par l’Application et le Site Web.
                        Votre situation est unique. Pour des conseils médicaux, nous vous invitons à consulter votre médecin et/ou votre clinique.
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    Définitions
                </div>
                <div>
                    <p>
                        <span>« Whitecoats»</span> : société à responsabilité limitée, propriétaire du Site Web et de l’Application digitale et fournisseur des Services au capital social de CHF 20’000.
                        Le siège social de Whitecoats, est situé Rue de Langallerie, 7, 1003 Lausanne, Suisse.
                        Elle est immatriculée au registre du commerce en Suisse sous le numéro CH-550.1.231.978-2, et représentée par Monsieur Pierre-Henri Freyssingeas en qualité de Président.
                    </p>
                    <p>
                        <span>« HoliYou »</span> : nom commercial de l’Application et du Site Web, développé par Whitecoats;
                    </p>
                    <p>
                        <span>« Abonné »</span> : Toute personne physique majeure capable, détenteur d’un compte et ayant contracté un abonnement pour accéder aux Services;
                    </p>
                    <p>
                        <span>« Application »</span> : Désigne l’application HoliYou disponible gratuitement depuis un smartphone par suite du téléchargement de celle-ci sur un magasin d’applications;
                    </p>
                    <p>
                        <span>« Moyens d’Accès »</span> : Méthodes et/ou fonction par lesquelles l’Utilisateur peut accéder à un ou plusieurs Services afin de les utiliser pour ses propres besoins;
                    </p>
                    <p>
                        <span>« Services »</span> : Ensemble des prestations (suivi des protocoles, carnet de santé, contenu, programme, chat médicalisé, communauté) mises à disposition de l’Utilisateur par HoliYou et accessibles via les Moyens d’Accès;
                    </p>
                    <p>
                        <span>« Tiers »</span> : Désigne des personnes physiques ou morales qui ne sont pas liées par une propriété ou un contrôle commun(e) à HoliYou ou d’autres individus sans relation avec HoliYou et/ou l’Utilisateur;
                    </p>
                    <p>
                        <span>« Utilisateur »</span> : Toute personne physique âgée de plus de 18 ans et jouissant de ses pleines capacités juridiques, ou toute personne morale de droit privé ou public navigant sur le Site Web , l’Application et/ou souscrivant au Service.
                    </p>
                    <p>
                        Les références aux Articles sont des références aux articles du présent Contrat, à moins qu’il n’en soit disposé autrement.
                    </p>
                    <p>
                        Toute référence au singulier inclut le pluriel et inversement.
                    </p>
                    <p>
                        Toute référence à un genre inclut l’autre genre.
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    Article 1. Objet et champ d’application des présentes Conditions Générales d’Utilisation
                </div>
                <div>
                    <p>
                        Les présentes conditions générales d’utilisation (ci-après les « CGU »), ont pour objet de définir les conditions dans lesquelles Whitecoats met à disposition des Utilisateurs
                        les Services disponibles sur l’Application digitale et son Site Web, ainsi que les obligations réciproques de chaque partie dans le cadre de l’utilisation desdits Services.
                    </p>
                    <p>
                        L’accès à l’Application est libre et gratuit. Il est néanmoins subordonné à l’acceptation des CGU et de la <a href='#pol-conf'>Politique de confidentialité</a>.
                    </p>
                    <p>
                        L’accès aux différents Services proposés par Whitecoats supposent l’acceptation pleine et entière des présentes CGU et de la Politique de confidentialité.
                        L’accès à certains Services peut nécessiter une inscription préalable et, le cas-échéant, l’acceptation complémentaires des «CGV».
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    Article 2. Acceptation et Capacité
                </div>
                <div>
                    <p>
                        L’Utilisateur accepte, sans réserve, les présentes CGU dans leur intégralité avant toute utilisation des Services.
                        Il déclare en conséquence être en pleine capacité juridique, conformément au droit suisse et européen.<br />
                        En se connectant à l’Application, l’Utilisateur reconnaît expressément avoir pris connaissance des présentes CGU et s’engage à les respecter.
                        Whitecoats se réserve la faculté de modifier les présentes CGU dans les conditions indiquées ci-après.
                        Les CGU applicables sont celles en vigueur à la date de validation de l'abonnement.

                    </p>
                    <p>
                        Les CGU sont disponibles à tout moment sur le Site www.holiyou.life ou sur l’Application.
                        L’Utilisateur accepte que les CGU ne lui soient pas envoyées sur support papier.
                    </p>
                    <p>
                        Si l’Utilisateur n’accepte pas ces conditions, il doit renoncer à toute utilisation du Site et des Services.
                    </p>
                    <p>
                        Pour toute demande d’informations ou tout contact, l’Utilisateur s’engage à contacter Whitecoats à l’adresse suivante : <a href='mailto:bonjour@holiyou.life' target={"_blank"} rel="noopener noreferrer">bonjour@holiyou.life</a>
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    Article 3. Modification des CGU
                </div>
                <div>
                    <p>
                        Whitecoats se réserve le droit de modifier, à tout moment et sans préavis, tout ou partie du site et des Services, ainsi que les CGU.
                        En conséquence, l’Utilisateur reconnaît et accepte qu’aucune indemnité de quelque nature que ce soit ne pourra leur être accordée à ce titre.
                    </p>
                    <p>
                        Il est, par ailleurs, conseillé à l’Utilisateur de se référer régulièrement à la dernière version des
                        CGU disponible en permanence sur le site Internet www.holiyou.life et sur l’Application.
                    </p>
                    <p>
                        Les modifications de l’Application, des Services et des CGU entrent en vigueur à la date de leur mise en ligne et sont opposables
                        à la date de la première utilisation par l’Utilisateur et l’Utilisateur Inscrit,
                        sauf dispositions contraires figurant dans les mentions particulières liées à l’utilisation de certains Services,
                        l’utilisation de l’Application et des Services implique la pleine et entière acceptation de toute révision ou modification des Services et de ses CGU.
                    </p>
                    <p>
                        Sauf dispositions contraires, tout nouveau Service ou modifications des Services, ou de l’Application existant seront soumis aux CGU.
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    Article 4. Description des Services
                </div>
                <div>
                    <p>
                        Whitecoats propose, via son Application, les Services suivants, à usage strictement personnel et non commercial :
                    </p>
                    <div className='sousTitreCGU'>
                        Article 4.1. Service de Suivi de ses protocoles
                    </div>
                    <p>
                        Il s’agit d’un outil de visualisation et de suivi des protocoles médicaux
                        (FIV, insémination, stimulation ovarienne, transfert d’embryon et congélation d’ovocyte) rendu possible grâce à un algorithme.
                        Après inscription de l’Utilisateur sur l’Application, le Service est accessible sur abonnement, payant. Il ne peut être utilisé que via l’Application.
                    </p>
                    <div className='sousTitreCGU'>
                        Article 4.2. Carnet de santé
                    </div>
                    <p>
                        L’Utilisateur peut avoir accès via abonnement à un agenda dans lequel il peut inclure le suivi
                        de son protocole : RDV médicaux, examens, prise de médicaments, injections. L’Utilisateur complète par ses propres moyens son carnet de santé.
                    </p>
                    <div className='sousTitreCGU'>
                        Article 4.3. Contenu :
                    </div>
                    <p>
                        L’Utilisateur peut accéder au Contenu : audios, vidéos, podcasts, masterclass, quiz, fiches, illustrations, articles scientifiques via l’Application.
                        L’accès est payant et intervient dans le cadre de l’abonnement.
                        Les vidéos peuvent également être visionnées sur le Site Web selon les mêmes critères que via l’Application, c’est-à-dire, si l’Utilisateur est abonné au service payant.
                    </p>
                    <div className='sousTitreCGU'>
                        Article 4.4. Communauté :
                    </div>
                    <p>
                        L’Utilisateur peut accéder à un espace d’échange entre Utilisateurs exclusif de l’Application, sur l’Application uniquement.
                        L’Utilisateur peut prendre part à des discussions, créer un fil de discussion, répondre et poser des questions.
                        Whitecoats n’intervient pas et ne monitore pas les échanges qui ont lieu dans cet espace.
                        Il ne peut être tenu responsable de la teneur et de la qualité des échanges.
                    </p>
                </div>
                <div className='textViolet sousTitrePolitique'>
                    Article 5. Accès à l’Application et aux Services
                </div>
                <div>
                    <div className='sousTitreCGU'>
                        Article 5.1. Dispositions générales
                    </div>
                    <p>
                        L’Application et les Services sont accessibles à condition de disposer d’un terminal informatique permettant de se connecter au réseau internet et d’un accès internet.
                    </p>
                    <p>
                        Whitecoats met en œuvre tous les moyens raisonnables à sa
                        disposition pour assurer un accès de qualité au Site Web, à l’Application et aux Services, mais n’est tenue à aucune obligation d’y parvenir.
                    </p>
                    <p>
                        Whitecoats ne peut, en outre, être tenue responsable de tout dysfonctionnement du réseau
                        ou des serveurs ou de tout autre événement échappant au contrôle raisonnable, qui empêcherait ou dégraderait l’accès au Site Web, à l’Application et aux Services.
                    </p>
                    <p>
                        Whitecoats se réserve la possibilité d’interrompre, de suspendre momentanément ou de modifier sans préavis l’accès à tout ou partie du Site Web, de l’Application ou Services,
                        afin d’en assurer la maintenance, ou pour toute autre raison, sans que l’interruption n’ouvre droit à aucune obligation ni indemnisation.
                    </p>
                    <p>
                        L’accès à l’Application à partir d’un Smartphone, d’une tablette ou de tout autre objet connecté est de la responsabilité exclusive de l’Utilisateur.
                        Ces derniers doivent prendre toutes les dispositions nécessaires
                        (y compris avec son fournisseur d’accès internet et son fournisseur de téléphonie mobile avec accès à Internet) afin de savoir quels frais y sont associés.
                    </p>
                    <p>
                        En accédant à l’Application, ou en acceptant de recevoir des messages et des notifications de Whitecoats via son téléphone portable et/ou
                        via tout autre dispositif média connecté, l’Utilisateur accepte les frais encourus pour sa connexion Internet ou services mobiles fournis.
                        Whitecoats n’est nullement responsable du paiement de tels frais.
                    </p>
                    <p>
                        L’Utilisateur Inscrits accepte de n’entreprendre aucune activité de nature à entraver ou à nuire à l’Application et/ou les Services.
                    </p>
                    <p>
                        Whitecoats se réserve le droit de refuser l’accès aux Services,
                        unilatéralement et sans notification préalable, à tout Utilisateur ne respectant pas les présentes conditions d’utilisation.
                    </p>
                    <p>
                        Afin de respecter la vie privée de l’Utilisateur et de l’Utilisateur Inscrit, l’accès aux Services est limité à une utilisation manuelle.
                        Tout usage d’un robot est interdit.
                        Est notamment illégale l’utilisation automatique des Services aux fins de constitution de bases de données commerciales et de prospection automatisée d’adresses email.
                    </p>
                    <div className='sousTitreCGU'>
                        Article 5.2. Accès à l’Application
                    </div>
                    <p>
                        L’Application HoliYou est disponible gratuitement depuis un smartphone par suite du téléchargement de celle-ci sur un magasin d’applications, 24h/24 et 7j/7.
                    </p>
                    <p>
                        Pour utiliser l’Application, l’Utilisateur doit créer un Compte Personnel et s’identifier, dans les conditions fixées à l’article 7.
                    </p>
                    <div className='sousTitreCGU'>
                        Article 5.3. Accès aux Services
                    </div>
                    <p>
                        Pour accéder aux Services, l’Utilisateur doit créer un Compte personnel, dans les conditions fixées à l’article 7.
                    </p>
                </div>
                <div className='textViolet sousTitrePolitique'>
                    Article 6. Création d’un Compte Personnel
                </div>
                <div>
                    <div className='sousTitreCGU'>
                        Article 6.1. Création de compte
                    </div>
                    <p>
                        La Création d’un Compte personnel est obligatoire sur l’Application, pour accéder à l’ensemble des Services.
                    </p>
                    <p>
                        Pour créer son Compte, l’Utilisateur doit être une personne physique, âgée de dix-huit (18) ans ou ayant atteint l’âge légal dans son pays de résidence,
                        ayant toute capacité juridique, et se créer un Compte personnel, au moyen du formulaire se trouvant sur le Site ou l’Application.
                    </p>
                    <p>
                        L’Utilisateur doit renseigner les champs suivants :
                    </p>
                    <ul>
                        <li>Prénom;</li>
                        <li>Nom;</li>
                        <li>Numéro de téléphone;</li>
                        <li>Adresse email;</li>
                        <li>Mot de passe;</li>
                        <li>Pays de résidence;</li>
                        <li>Centre de PMA;</li>
                        <li>Type de protocole suivi;</li>
                        <li>Phase du protocole</li>
                    </ul>
                    <p>
                        La création d’un Compte personnel est subordonnée à une validation. L’Utilisateur reçoit un email sur son adresse, et dois valider son inscription en cliquant sur le lien .
                        La validation du Compte personnel permet à l’Utilisateur Inscrit d’utiliser les Services.
                    </p>
                    <p>
                        Tout Utilisateur garantit à Whitecoats que les Données Personnelles qu’il communique lors de son inscription en complétant le Profil sont exactes,
                        complètes et régulièrement mises à jour.
                        La fourniture de ces Données Personnelles et leur maintien à jour, constituent une des conditions déterminantes du droit d’utiliser les Services.
                    </p>
                    <p>
                        L’Utilisateur demeure libre de modifier, à tout moment, la teneur de ces Données Personnelles, selon les modalités visées à l’article « Données Personnelles » ci-dessous.
                    </p>
                    <p>
                        Whitecoats pourra à sa seule discrétion supprimer le Profil d’un Utilisateur de manière temporaire et/ou définitive, si ce dernier ne respecte pas les dispositions des présentes CGU.
                    </p>
                    <div className='sousTitreCGU'>
                        Article 6.2. Responsabilité
                    </div>
                    <p>
                        La sécurité de votre Compte personnel est de votre seule responsabilité. Whitecoats ne pourra pas être tenue pour responsable des dommages que pourrait subir votre Compte personnel ou votre ordinateur suite à la perte ou au partage des identifiants de votre Compte personnel.
                    </p>
                    <p>
                        Whitecoats ne pourra en aucune manière être reconnue responsable en cas de vol de compte ou de toutes altérations que pourra subir votre Compte personnel.
                    </p>
                    <p>
                        Vous reconnaissez également que vous êtes présumé être l’Utilisateur de votre Compte personnel et le responsable des actions entreprises via votre Compte et sur votre Compte.
                    </p>
                    <p>
                        Vous reconnaissez que vous, et non pas Whitecoats, êtes responsable de toutes les communications électroniques et des contenus envoyés par vos soins et que vous devez utiliser les Services dans le respect des lois applicables.
                    </p>
                    <div className='sousTitreCGU'>
                        Article 6.3. Perte d’identifiant
                    </div>
                    <p>
                        L’identifiant et le mot de passe de l’Utilisateur Inscrit sont strictement personnels et confidentiels.
                    </p>
                    <p>
                        L’Utilisateur Inscrit engage sa responsabilité s’il communique ces éléments à des Tiers, il est conseillé à l’Utilisateur de se déconnecter de son Compte Personnel à la fin de chaque utilisation.
                    </p>
                    <p>
                        L’Utilisateur Inscrit est responsable du maintien de la confidentialité de son identifiant et de son mot de passe.
                    </p>
                    <p>
                        En cas de problème de sécurité concernant les Services et, le cas-échéant, l’Application, notamment relatif à l’identifiant et/ou au mot de passe, l’Utilisateur concerné s’oblige à en informer immédiatement Whitecoats.
                    </p>
                    <p>
                        Tout Utilisateur rencontrant un quelconque risque quant à son mot de passe doit le changer immédiatement après avoir informé Whitecoats. L’Utilisateur reconnaît à Whitecoats le droit de prendre toutes mesures appropriées en pareil cas.
                    </p>
                    <p>
                        En cas de perte de son mot de passe, l’Utilisateur pourra créer un nouveau mot de passe. Pour cela, l’utilisateur recevra un email à l’adresse email de son compte avec un code à 6 chiffres lui permettant de créer un nouveau mot de passe directement sur l’application.
                    </p>
                    <div className='sousTitreCGU'>
                        Article 6.4. Suppression de compte
                    </div>
                    <p>
                        L’Utilisateur peut, à tout moment, supprimer son Compte personnel en en faisant la demande à l’adresse
                        <a href='mailto:bonjour@holiyou.life' target={"_blank"} rel="noopener noreferrer">bonjour@holiyou.life</a>. Si vous supprimez votre Compte personnel,
                        vous n’aurez plus accès aux Services.
                    </p>
                    <p>
                        Avant de supprimer votre Compte personnel, sachez que nous conservons vos informations pendant seulement 30 (trente) jours
                        après la date de désactivation. Passé ce délai, nous lançons le processus de suppression de votre compte de nos systèmes.
                    </p>
                    <p>
                        La suppression définitive de votre compte signifie que vous ne pourrez plus jamais le réactiver, ni récupérer son contenu ou ses informations.
                        Si vous souhaitez supprimer définitivement votre Compte, sans aucune option de récupération, n’hésitez pas à nous contacter.
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    Article 7. Obligations générales de l’Utilisateur et de l’Utilisateur Inscrit
                </div>
                <div>
                    <p>
                        L’Utilisateur doit utiliser le site conformément à son usage.
                    </p>
                    <p>
                        Whitecoats se réserve le droit de supprimer, sans préavis ni indemnité ni droit à remboursement,
                        le Compte personnel de tout Utilisateur Inscrit qui ne respecterait pas les règles exposées dans les présentes CGU et/ou
                        qui serait susceptible de porter atteinte aux droits d’un Tiers.
                    </p>
                    <p>
                        Vous n’êtes pas autorisé à utiliser des dispositifs, programmes, algorithmes ou autres méthodes automatiques de type « lien profond »,
                        « gratte-pages », « robot » ou « araignée », ou tout autre processus manuel similaire ou équivalent, pour accéder à, acquérir,
                        copier ou surveiller toute partie de l’Application et de son Site Web ou Contenu ou Programme, ni à reproduire ou contourner la structure navigationnelle
                        ou la présentation de l’Application et de son Site Web, du Suivi des Protocoles ou du Carnet de Santé ou du Contenu ou Programme
                        pour vous procurer ou essayer de vous procurer des données,
                        des documents ou des informations par des moyens non mis à dessein à votre disposition par le biais de l’Application.
                    </p>
                    <p>
                        Vous ne devez pas essayer d’accéder de façon illicite aux Services, par piratage informatique, « reniflage » de mots de passe ou tout autre moyen illégitime.
                    </p>
                    <p>
                        Vous ne devez pas essayer de sonder, d’analyser ou de tester la vulnérabilité de l’Application ou de tout réseau connecté à l’Application,
                        ni enfreindre les mesures de sécurité et d’authentification mises en place sur l’Application ou les réseaux connectés à l’Application.
                    </p>
                    <p>
                        Vous vous engagez à n’utiliser aucun dispositif, logiciel ou sous-programme pour interférer ou essayer d’interférer sur le bon fonctionnement de l’Application et des Services.
                    </p>
                    <p>
                        Vous ne devez pas essayer de contrefaire les en-têtes ou
                        manipuler les identifiants de quelque manière que ce soit pour déguiser l’origine d’un message ou d’une transmission envoyée par Whitecoats sur ou via l’Application et les Services.
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    Article 8. Responsabilité
                </div>
                <div>
                    <p>
                        Whitecoats mettra tout en œuvre pour assurer le bon fonctionnement de l’Application et de son Site Web ainsi que des Services et vous les fournir dans les conditions fixées à l’article 6 (24h/24, 7 jours/7).
                    </p>
                    <p>
                        Néanmoins Whitecoats n’est tenu que par une obligation de moyens concernant l’accès et l’utilisation du Service. Whitecoats ne peut pas garantir que les fonctionnalités proposées par l’Application, le Site Web et les Services seront toujours disponibles ou sans erreurs typographiques, techniques ou autres, que les défauts seront corrigés ou que l’Application le Site Web, les Services ou serveurs qui l’hébergent sont exempts de bugs.
                    </p>
                    <p>
                        Pour des motifs de maintenance, de test, de réparation ou de toute autre nature liés à l’amélioration et au fonctionnement du Service, celui-ci pourra être interrompu temporairement par Whitecoats, sans que sa responsabilité ne puisse être engagée. Whitecoats décidera seule de vous accorder ou non une compensation en cas d’interruption du Service.
                    </p>
                    <p>
                        La responsabilité de Whitecoats ne saurait être engagée dans les cas de force majeure prévus par la loi et telle que définie par la jurisprudence des cours et tribunaux Suisses y compris l’interruption, la suspension, la réduction ou les dérangements d’approvisionnement en électricité ou autres ou toutes interruptions de réseaux de télécommunications.
                    </p>
                    <p>
                        Whitecoats est tenue à une obligation de moyens dans le cadre des présentes Conditions Générales et ne saurait en aucun cas être responsable de toute perte, préjudice ou dommage indirect de quelque nature que ce soit résultant de la gestion, l’utilisation, l’exploitation, d’une interruption ou d’un dysfonctionnement de l’Application et/ou du Service.
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    Article 9. Propriété intellectuelle
                </div>
                <div>
                    <p>
                        Tous les éléments du Site disponible sur <a href="https://holiyou.life" target="_blank" rel="noopener noreferrer">https://holiyou.life</a> de l’Application et des Services, et notamment les marques déposées, les textes, graphismes, logos, sons, photos, vidéos, animations, codes informatiques sont la propriété exclusive d’HoliYou.
                    </p>
                    <p>
                        Les éléments appartenant à Whitecoats, et à fortiori leur mise en forme ainsi que le(s) code(s) qui en résulte(nt), sont la propriété exclusive de Whitecoats (notamment le design, la structure, la sélection, la coordination, l’expression, l’aspect et la convivialité, la présentation et l’agencement de ce Contenu intellectuel, figurant sur le Site Web et les Services est détenu, contrôlé ou cédé sous licence par Whitecoats, et est protégé par la législation sur l’habillage commercial, les droits d’auteur, les brevets et les marques, et diverses autres lois applicables en matière de propriété intellectuelle et de concurrence déloyale).
                    </p>
                    <p>
                        La protection du Site Web <a href="https://holiyou.life" target="_blank" rel="noopener noreferrer">https://holiyou.life</a> de l’Application et des Services relève, par conséquent, de chacune des législations nationales et internationales relatives au droit de la propriété intellectuelle.
                    </p>
                    <p>
                        Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du Site Web <a href="https://holiyou.life" target="_blank" rel="noopener noreferrer">https://holiyou.life</a> ou de l’Application, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de Whitecoats.
                    </p>
                    <p>
                        Toute exploitation non autorisée du Site Web <a href="https://holiyou.life" target="_blank" rel="noopener noreferrer">https://holiyou.life</a> des Services ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                    </p>
                    <p>
                        Les codes d’accès au Service sont individuels et ne doivent pas être partagés. Si l’Utilisateur partage ses codes d’accès, l’accès sera automatiquement bloqué.
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    Article 10. Données personnelles
                </div>
                <div>
                    <p>
                        La Politique de confidentialité (<a href='#pol-conf'>disponible ici</a>) vous informe de manière exhaustive
                        de la manière dont nous recueillons et traitons vos données personnelles.
                    </p>
                </div>
                <div className='textViolet sousTitrePolitique'>
                    Article 11. Force Majeure
                </div>
                <div>
                    <p>
                        Il y a force majeure lorsqu’un événement échappant au contrôle du débiteur de l’obligation, qui ne pouvait être raisonnablement
                        prévu lors de l’acceptation des CGU et dont les effets ne peuvent être évités par des mesures appropriées, empêche l’exécution de son obligation par le débiteur.
                    </p>
                    <p>
                        Le cas de force majeure suspend les obligations de la partie concernée pendant le temps où jouera la force majeure
                        si cet évènement est temporaire. Néanmoins, les Parties s’efforceront d’en minimiser dans toute la mesure du possible les conséquences.
                    </p>
                    <p>
                        A défaut, si l’empêchement est définitif, les parties seront libérées de leurs obligations dans les conditions prévues aux articles 1351 et 1351-1 du Code civil.
                    </p>
                </div>
                <div className='textViolet sousTitrePolitique'>
                    Article 12. Convention de preuve
                </div>
                <div>
                    <p>
                        Les messages reçus par télécopie ou par voie électronique et plus généralement les documents électroniques échangés entre Whitecoats
                        et l’Utilisateur sont des écrits d’origine au sens de l’article 1366 du Code civil, c’est à dire comme ayant la même valeur que celle accordée à l’original.
                        Il convient de conserver les télécopies ou les écrits électroniques de telle manière qu’ils puissent constituer des copies fidèles
                        et durables au sens de l’article 1379 du Code civil.
                    </p>
                </div>
                <div className='textViolet sousTitrePolitique'>
                    Article 13. Renonciation
                </div>
                <div>
                    <p>
                        Le fait pour l’une ou l’autre des Parties de ne pas se prévaloir d’une ou plusieurs stipulations des CGU ne pourra en aucun cas impliquer
                        la renonciation par cette Partie à s’en prévaloir ultérieurement.
                    </p>
                </div>
                <div className='textViolet sousTitrePolitique'>
                    Article 14. Nullité partielle
                </div>
                <div>
                    <p>
                        L’annulation éventuelle d’une ou plusieurs clauses du présent contrat par une décision de justice ou encore d’un commun accord entre Whitecoats et un Utilisateur
                        ne saurait porter atteinte à ses autres stipulations qui continueront de produire leur plein et entier effet pour autant que l’économie générale du contrat puisse être sauvegardée.
                    </p>
                </div>
                <div className='textViolet sousTitrePolitique'>
                    Article 15. Langue, loi applicable
                </div>
                <div>
                    <p>
                        De convention expresse entre Whitecoats et les Utilisateurs, les présentes Conditions Générales d’Utilisation sont régies par le droit français.
                    </p>
                    <p>
                        Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues, seul le texte français ferait foi en cas de litige.
                    </p>
                    <p>
                        Les présentes CGU sont régies par le droit Suisse.
                    </p>
                </div>
                <div className='textViolet sousTitrePolitique'>
                    Article 16. Attribution de juridiction
                </div>
                <div>
                    <p>
                        Tous les litiges auxquels le présent contrat pourrait donner lieu, concernant tant sa validité, son interprétation, son exécution, sa résolution,
                        leurs conséquences et leurs suites seront soumis de la compétence exclusive des tribunaux du siège de HoliYou
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Cgu;
