import {
  CULTURE_EMBRYONNAIRE,
  DECLENCHEMENT,
  IMPLANTATION,
  PREPARATION,
  REPOS,
  STIMULATION,
  SYNCHRONISATION,
  TRANSFERT
} from "./constants";

export const Hypnose = [
  {
    title: 'Hypnose FIV',
    phase: STIMULATION,
    duration: 14,
    uri: "https://holiyou.life/Hypnose/Hypnose%20-%20FIV/Export/Hypnose%20FIV%20v2%20MUSIC.wav",
    preview: require("../previews/Hypnose.png")
  },
  {
    title: 'Hypnose Relaxation',
    phase: STIMULATION,
    duration: 11,
    uri: "https://holiyou.life/Hypnose/Hypnose%20-%20Relaxation/Hypnose%20-%20Relaxation%20v1.wav",
    preview: require("../previews/Hypnose.png")
  },
  {
    title: 'Lâcher Prise',
    phase: STIMULATION,
    duration: 5,
    uri: "https://holiyou.life/Hypnose/Hypnose%20-%20L%c3%a2cher%20prise/Hypnose%20-%20L%c3%a2cher%20prise%20v1.wav",
    preview: require("../previews/Hypnose.png")
  },
]

export const HypnoseTextes = [
  {
    phase: PREPARATION,
    content:
      "Les audios d'hypnose peuvent vous aider à apaiser votre esprit et votre corps, vous offrant un précieux soutien émotionnel lors de votre parcours. \n" +
      "Ces hypnoses ont été conçues spécialement pour les parcours de PMA et peuvent être écoutées plusieurs fois dans la journée, selon vos besoins."
  },
  {
    phase: SYNCHRONISATION,
    content:
      "Les audios d'hypnose peuvent vous aider à apaiser votre esprit et votre corps, vous offrant un précieux soutien émotionnel lors de votre parcours. \n" +
      "Ces hypnoses ont été conçues spécialement pour les parcours de PMA et peuvent être écoutées plusieurs fois dans la journée, selon vos besoins."
  },
  {
    phase: REPOS,
    content:
      "Les audios d'hypnose peuvent vous aider à apaiser votre esprit et votre corps, vous offrant un précieux soutien émotionnel lors de votre parcours. \n" +
      "Ces hypnoses ont été conçues spécialement pour les parcours de PMA et peuvent être écoutées plusieurs fois dans la journée, selon vos besoins."
  },

  {
    phase: STIMULATION,
    content:
      "Les audios d'hypnose peuvent vous aider à apaiser votre esprit et votre corps, vous offrant un précieux soutien émotionnel lors de votre parcours. \n" +
      "Ces hypnoses ont été conçues spécialement pour les parcours de PMA et peuvent être écoutées plusieurs fois dans la journée, selon vos besoins."
  },
  {
    phase: DECLENCHEMENT,
    content:
      "Les audios d'hypnose peuvent vous aider à apaiser votre esprit et votre corps, vous offrant un précieux soutien émotionnel lors de votre parcours. \n" +
      "Ces hypnoses ont été conçues spécialement pour les parcours de PMA et peuvent être écoutées plusieurs fois dans la journée, selon vos besoins."
  },
  {
    phase: CULTURE_EMBRYONNAIRE,
    content:
      "Les audios d'hypnose peuvent vous aider à apaiser votre esprit et votre corps, vous offrant un précieux soutien émotionnel lors de votre parcours. \n" +
      "Ces hypnoses ont été conçues spécialement pour les parcours de PMA et peuvent être écoutées plusieurs fois dans la journée, selon vos besoins."
  },
  {
    phase: TRANSFERT,
    content:
      "Les audios d'hypnose peuvent vous aider à apaiser votre esprit et votre corps, vous offrant un précieux soutien émotionnel lors de votre parcours. \n" +
      "Ces hypnoses ont été conçues spécialement pour les parcours de PMA et peuvent être écoutées plusieurs fois dans la journée, selon vos besoins."
  },
  {
    phase: IMPLANTATION,
    content:
      "Les audios d'hypnose peuvent vous aider à apaiser votre esprit et votre corps, vous offrant un précieux soutien émotionnel lors de votre parcours. \n" +
      "Ces hypnoses ont été conçues spécialement pour les parcours de PMA et peuvent être écoutées plusieurs fois dans la journée, selon vos besoins."
  },
]
