export const PREPARATION = "Préparation"
export const SYNCHRONISATION = "Synchronisation"
export const REPOS = "Repos"
export const STIMULATION = "Stimulation"
export const DECLENCHEMENT = "Déclenchement"
export const PONCTION = "Ponction"
export const CULTURE_EMBRYONNAIRE = "Culture Embryonnaire"
export const TRANSFERT = "Transfert"
export const IMPLANTATION = "Implantation"

export const PrendreSoinDeMoiTextes = [
    {
        phase: PREPARATION,
        content:
            "Ça y est, vous débutez un protocole de FIV.\n" +
            "Pendant les quelques jours de cette phase, votre corps se synchronise pour préparer le bon déroulement des prochaines étapes.\n" +
            "\n" +
            "Accordez-vous le droit de prendre soin de vous en partageant une routine seule ou avec votre partenaire."
    },
    {
        phase: SYNCHRONISATION,
        content:
            "Ça y est, vous débutez un protocole de FIV.\n" +
            "Pendant les quelques jours de cette phase, votre corps se synchronise pour préparer le bon déroulement des prochaines étapes.\n" +
            "\n" +
            "Accordez-vous le droit de prendre soin de vous en partageant une routine seule ou avec votre partenaire."
    },
    {
        phase: REPOS,
        content:
            "Nous vous proposons durant ces quelques jours de repos, des exercices doux et relaxants pour vous mettre dans les meilleures conditions pour votre phase de stimulation qui débutera prochainement.\n" +
            "\n" +
            "A faire seul.e ou à deux, sans modération et à tout moment de votre parcours."
    },
    {
        phase: STIMULATION,
        content:
            "Équilibrez votre corps et votre esprit avec ces exercices conçus exclusivement pour la phase de stimulation ovarienne.\n" +
            "\n" +
            "Cette phase peut-être éprouvante, alors il est essentiel de prendre soin de vous que ce soit en suivant ce programme ou tout simplement en vous accordant quelques petits plaisirs.\n" +
            "\n" +
            "Vous avez tout notre soutien !"
    },
    {
        phase: DECLENCHEMENT,
        content:
            "Ces jours-là, le corps peut être alourdi par les traitements et l'esprit, tendu. En intégrant le yoga et la méditation à votre routine, vous trouverez du confort et de la détente, mais aussi vous renforcerez votre corps, apaiserez votre esprit et favoriserez une meilleure gestion du stress pour une expérience plus douce et équilibrée.\n" +
            "\n" +
            "Ces exercices ont été spécialement élaborés pour vous accompagner sur ces étapes charnières de votre parcours."
    },
    {
        phase: "Déclenchement Ovulation",
        content:
            "Ces jours-là, le corps peut être alourdi par les traitements et l'esprit, tendu. En intégrant le yoga et la méditation à votre routine, vous trouverez du confort et de la détente, mais aussi vous renforcerez votre corps, apaiserez votre esprit et favoriserez une meilleure gestion du stress pour une expérience plus douce et équilibrée.\n" +
            "\n" +
            "Ces exercices ont été spécialement élaborés pour vous accompagner sur ces étapes charnières de votre parcours."
    },
    {
        phase: "Déclenchement Ovulation 2",
        content:
            "Ces jours-là, le corps peut être alourdi par les traitements et l'esprit, tendu. En intégrant le yoga et la méditation à votre routine, vous trouverez du confort et de la détente, mais aussi vous renforcerez votre corps, apaiserez votre esprit et favoriserez une meilleure gestion du stress pour une expérience plus douce et équilibrée.\n" +
            "\n" +
            "Ces exercices ont été spécialement élaborés pour vous accompagner sur ces étapes charnières de votre parcours."
    },
    {
        phase: PONCTION,
        content:
            "Ces jours-là, le corps peut être alourdi par les traitements et l'esprit, tendu. En intégrant le yoga et la méditation à votre routine, vous trouverez du confort et de la détente, mais aussi vous renforcerez votre corps, apaiserez votre esprit et favoriserez une meilleure gestion du stress pour une expérience plus douce et équilibrée.\n" +
            "\n" +
            "Ces exercices ont été spécialement élaborés pour vous accompagner sur ces étapes charnières de votre parcours."
    },
    {
        phase: CULTURE_EMBRYONNAIRE,
        content:
            "Entre la ponction et le transfert d'embryon, les exercices que nous vous recommandons sont conçus pour induire la détente, favorisant ainsi les conditions idéales pour un transfert réussi. De plus, ils contribuent à l'équilibre émotionnel, ce qui est essentiel pour un processus déjà chargé en émotions.\n" +
            "\n" +
            "Félicitez-vous pour tout le chemin accompli. Vous touchez bientôt au but."
    },
    {
        phase: "Culture Embryonnaire Avec DPI-A",
        content:
            "Entre la ponction et le transfert d'embryon, les exercices que nous vous recommandons sont conçus pour induire la détente, favorisant ainsi les conditions idéales pour un transfert réussi. De plus, ils contribuent à l'équilibre émotionnel, ce qui est essentiel pour un processus déjà chargé en émotions.\n" +
            "\n" +
            "Félicitez-vous pour tout le chemin accompli. Vous touchez bientôt au but."
    },
    {
        phase: "Culture Embryonnaire Sans DPI-A",
        content:
            "Entre la ponction et le transfert d'embryon, les exercices que nous vous recommandons sont conçus pour induire la détente, favorisant ainsi les conditions idéales pour un transfert réussi. De plus, ils contribuent à l'équilibre émotionnel, ce qui est essentiel pour un processus déjà chargé en émotions.\n" +
            "\n" +
            "Félicitez-vous pour tout le chemin accompli. Vous touchez bientôt au but."
    },
    {
        phase: "Analyse",
        content:
            "Entre la ponction et le transfert d'embryon, les exercices que nous vous recommandons sont conçus pour induire la détente, favorisant ainsi les conditions idéales pour un transfert réussi. De plus, ils contribuent à l'équilibre émotionnel, ce qui est essentiel pour un processus déjà chargé en émotions.\n" +
            "\n" +
            "Félicitez-vous pour tout le chemin accompli. Vous touchez bientôt au but."
    },
    {
        phase: "Monitorage",
        content:
            "Entre la ponction et le transfert d'embryon, les exercices que nous vous recommandons sont conçus pour induire la détente, favorisant ainsi les conditions idéales pour un transfert réussi. De plus, ils contribuent à l'équilibre émotionnel, ce qui est essentiel pour un processus déjà chargé en émotions.\n" +
            "\n" +
            "Félicitez-vous pour tout le chemin accompli. Vous touchez bientôt au but."
    },
    {
        phase: TRANSFERT,
        content:
            "Ce jour-là est une grande étape.\n" +
            "Si vous souhaitez vous détendre l'esprit et le corps, nous vous proposons de pratiquer un Shavasna.\n" +
            "\n" +
            "Nous vous soutenons de tout cœur ❤️"
    },
    {
        phase: "Transfert Frais",
        content:
            "Ce jour-là est une grande étape.\n" +
            "Si vous souhaitez vous détendre l'esprit et le corps, nous vous proposons de pratiquer un Shavasna.\n" +
            "\n" +
            "Nous vous soutenons de tout cœur ❤️"
    },
    {
        phase: "Transfert 1",
        content:
            "Ce jour-là est une grande étape.\n" +
            "Si vous souhaitez vous détendre l'esprit et le corps, nous vous proposons de pratiquer un Shavasna.\n" +
            "\n" +
            "Nous vous soutenons de tout cœur ❤️"
    },
    {
        phase: "Transfert 2",
        content:
            "Ce jour-là est une grande étape.\n" +
            "Si vous souhaitez vous détendre l'esprit et le corps, nous vous proposons de pratiquer un Shavasna.\n" +
            "\n" +
            "Nous vous soutenons de tout cœur ❤️"
    },
    {
        phase: "THS",
        content:
            "Ce jour-là est une grande étape.\n" +
            "Si vous souhaitez vous détendre l'esprit et le corps, nous vous proposons de pratiquer un Shavasna.\n" +
            "\n" +
            "Nous vous soutenons de tout cœur ❤️"
    },
    {
        phase: IMPLANTATION,
        content:
            "Durant cette phase de post transfert ou d'implantation de l'embryon, nous cherchons l'appaisement du système nerveux ainsi que la détente dans la région du bassin et du ventre. Des pratiques donc très douces pour vous accompagner durant cette attente."
    },
]

export const getUniqueVideos = (videos) => {
    const seenTitles = new Set();
    return videos.filter(video => {
        if (seenTitles.has(video.title)) {
            return false;
        }
        seenTitles.add(video.title);
        return true;
    });
};
