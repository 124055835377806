import {
  CULTURE_EMBRYONNAIRE,
  DECLENCHEMENT,
  IMPLANTATION,
  PREPARATION,
  REPOS,
  STIMULATION,
  SYNCHRONISATION,
  TRANSFERT
} from "./constants";

export const Meditation = [
  {
    title: 'Méditation Stimulation',
    phase: "Stimulation",
    duration: 14,
    uri: "https://holiyou.life/videos/02-Stimulation/Stimulation%20-%20Me%cc%81ditation%20H264%203Mbps.mp4",
    preview: require("../previews/Meditation.png")
  },

  {
    title: 'Méditation Recovery',
    phase: "Repos",
    duration: 14,
    uri: "https://holiyou.life/videos/06-recovery/Recovery%20-%20Me%cc%81ditation%20H264%203Mbps.mp4",
    preview: require("../previews/Meditation.png")
  },

  {
    title: 'Méditation Pré-Ponction',
    phase: "Déclenchement",
    duration: 14,
    uri: "https://holiyou.life/videos/03-Pr%c3%a9ponction/Pre%cc%81%20Ponction%20-%20Me%cc%81ditation%20H264%203Mbps.mp4",
    preview: require("../previews/Meditation.png")
  },

  {
    title: 'Méditation Pré-Transfert',
    phase: "Culture Embryonnaire",
    duration: 14,
    uri: "https://holiyou.life/videos/04-Pr%c3%a9transfert/Pre%cc%81%20Transfert%20-%20Me%cc%81ditation%20H264%203Mbps.mp4",
    preview: require("../previews/Meditation.png")
  },
  {
    title: 'Méditation Recovery',
    phase: "Culture Embryonnaire",
    duration: 14,
    uri: "https://holiyou.life/videos/06-recovery/Recovery%20-%20Me%cc%81ditation%20H264%203Mbps.mp4",
    preview: require("../previews/Meditation.png")
  },

  {
    title: 'Méditation Implantation',
    phase: "Implantation",
    duration: 14,
    uri: "https://holiyou.life/videos/05-Posttransfert/Post%20Transfert%20-%20Me%cc%81ditation%20H264%203Mbps.mp4",
    preview: require("../previews/Meditation.png")
  },
  {
    title: 'Méditation Recovery',
    phase: "Implantation",
    duration: 14,
    uri: "https://holiyou.life/videos/06-recovery/Recovery%20-%20Me%cc%81ditation%20H264%203Mbps.mp4",
    preview: require("../previews/Meditation.png")
  },
]

export const MeditationTextes = [
  {
    phase: PREPARATION,
    content:
      "Restaurez votre corps et mental à travers les exercices de méditation.\n" +
      "A pratiquer seule ou en duo"
  },
  {
    phase: SYNCHRONISATION,
    content:
      "Restaurez votre corps et mental à travers les exercices de méditation.\n" +
      "A pratiquer seule ou en duo"
  },
  {
    phase: REPOS,
    content:
      "Restaurez votre corps et mental à travers les exercices de méditation.\n" +
      "A pratiquer seule ou en duo"
  },
  {
    phase: STIMULATION,
    content:
      "Pendant votre phase de stimulation, la méditation peut vous apporter un soulagement en favorisant la relaxation et la gestion du stress, améliorant ainsi votre bien-être général.",
  },
  {
    phase: DECLENCHEMENT,
    content:
      "Cette méditation vous accompagne pour renforcer votre confiance en vous et vous aider à être plus ancré.e.s dans le moment présent."
  },
  {
    phase: CULTURE_EMBRYONNAIRE,
    content:
      "Les exercices de méditation post-ponction atténuent les douleurs, favorisent la relaxation musculaire et stimulent le bien-être. La méditation renforce la résilience, augmente la confiance et apporte un soutien précieux pour votre traitement. Intégrez-les sans hésiter dans votre routine pour un soulagement optimal."
  },
  {
    phase: TRANSFERT,
    content:
      "Restaurez votre corps et mental à travers les exercices de méditation.\n" +
      "A pratiquer seule ou en duo"
  },
  {
    phase: IMPLANTATION,
    content:
      "Une méditation pour apaiser l'esprit et favoriser un environnement corporel propice à la réussite du transfert."
  },
]
