import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {toast} from "react-toastify";
import {getUniqueVideos} from "./constant/constants";
import {Hypnose} from "./constant/hypnose";
import {Yoga} from "./constant/yoga";
import RecommendedSection from "./PrendreSoinDeMoi/RecommendedSection";
import './PrendreSoinDeMoi.css'
import {Meditation} from "./constant/meditation";

const PrendreSoinDeMoi = () => {
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Function to fetch user information
    const fetchInformationForRegisteredUser = async (id) => {
        try {
            const response = await fetch(`https://api.holiyou.life/API/user/${id}/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    relations: [
                        "token", "protocols", 'protocols.path', 'protocols.transitions', 'protocols.transitions.day',
                        "protocols.phases", "protocols.phases.children", "protocols.phases.parent", 'protocols.phases.days',
                        'protocols.phases.days.events', 'protocols.phases.days.transitions'
                    ]
                })
            });

            if (response.ok) {
                return await response.json();
            } else {
                throw new Error('Failed to fetch user information');
            }
        } catch (error) {
            console.error('fetchInformationForRegisteredUser Error:', error);
            return null;
        }
    };

    // Function to fetch protocols for today
    const fetchProtocolToday = async (id, date) => {
        try {
            const response = await fetch(`https://api.holiyou.life/API/user/${id}/today/${date}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                throw new Error('Failed to fetch protocols for today');
            }
        } catch (error) {
            console.error('fetchProtocolToday Error:', error);
            return null;
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            const userId = localStorage.getItem('userId');
            if (userId) {
                // Fetch user information
                const userData = await fetchInformationForRegisteredUser(userId);

                if (userData) {
                    // Fetch today's date
                    const todayDate = new Date().toISOString().split('T')[0];

                    // Fetch protocols for today
                    const protocolsForToday = await fetchProtocolToday(userId, todayDate);

                    // Extract relevant data
                    const protocolData = protocolsForToday.protocols.map(protocol => {
                        const phase = protocol.path.phases[0]; // Assuming there's only one phase for simplicity
                        const day = phase.days[0]; // Assuming there's only one day for simplicity

                        return {
                            centre: protocol.centre,
                            protocol: protocol.name,
                            phase: phase.name,
                            jourPhase: day.jourPhase,
                            duration: phase.duration
                        };
                    });

                    setUserData(protocolData[0]);
                } else {
                    setError('Failed to fetch user data');
                }
            } else {
                setError('User ID not found in localStorage');
                navigate('/'); // Redirect to the home page if user ID is not available
            }
        };

        fetchUserData();
    }, [navigate]);

    if (error || !userData) {
        toast.error('Une erreur est survenue lors de la connexion à votre compte, veuillez réessayer plus tard');
        navigate('/');
    }

    const handleLogout = () => {
        // Clear user data from localStorage and redirect to home page
        localStorage.removeItem('userId');

        navigate('/');
    };

    return (userData &&
        <div className={"dashboard"}>
            {/* Sidebar */}
            <div className={"sidebar"}>
                <h2 className={"sidebarTitle"}>Prendre soin de moi</h2>
                <ul className={"sidebarList"}>
                    <li className={"sidebarItem"}>
                        <a href="#meditation" className={"sidebarLink"}>{"Médiation"}</a>
                    </li>
                    <li className={"sidebarItem"}>
                        <a href="#yoga" className={"sidebarLink"}>{"Yoga"}</a>
                    </li>
                    <li className={"sidebarItem"}>
                        <a href="#hypnose" className={"sidebarLink"}>{"Hypnose"}</a>
                    </li>
                </ul>
            </div>

            {/* Main Content Area */}
            <div className={"mainContent"}>
                <div className={"header"}>
                    <h1 className={"headerTitle"}>
                        Bienvenue sur votre espace HoliYou
                    </h1>
                    <button onClick={handleLogout} className={"logoutButtonHeader"}>
                        Se déconnecter
                    </button>
                </div>

                {/* Recommended Videos Section */}
                <RecommendedSection userData={userData} />

                {/* Carousel Sections */}
                <section id="meditation" className={"carouselSection"}>
                    <h2 className={"sectionTitle"}>Méditation</h2>
                    <p className={"sectionDescription"}>
                        {"Restaurez votre corps et mental à travers les exercices de méditation. A pratiquer seule ou en duo"}
                    </p>
                    <div className={"carouselContainer"}>
                        {Meditation.map((content, index) => (
                            <div key={index} className={"carouselItem"}>
                                <div className={"card"}>
                                    <h3>{`${content.title}`}</h3>
                                    <a href={content.uri} target="_blank" rel="noopener noreferrer">
                                        <img src={content.preview} alt={content.title} className={"videoImage"}/>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                <section id="yoga" className={"carouselSection"}>
                    <h2 className={"sectionTitle"}>Yoga</h2>
                    <p className={"sectionDescription"}>
                        {"La pratique régulière du yoga, ainsi que du Shavasana, favorise la détente, améliore le bien-être global et diminue le stress. L'intégration de ces exercices tout au long de votre parcours de PMA favorise une relaxation profonde, apaisant à la fois le corps et l'esprit pour un meilleur équilibre émotionnel. Que ce soit seule ou en duo, elles vous offriront un précieux moment de bien-être."}
                    </p>
                    <div className={"carouselContainer"}>
                        {getUniqueVideos(Yoga).map((content, index) => (
                            <div key={index} className={"carouselItem"}>
                                <div className={"card"}>
                                    <h3>{`${content.title}`}</h3>
                                    <a href={content.uri} target="_blank" rel="noopener noreferrer">
                                        <img src={content.preview} alt={content.title} className={"videoImage"}/>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                <section id="hypnose" className={"carouselSection"}>
                    <h2 className={"sectionTitle"}>Hypnose</h2>
                    <p className={"sectionDescription"}>
                        {"Les audios d'hypnose peuvent vous aider à apaiser votre esprit et votre corps, vous offrant un précieux soutien émotionnel lors de votre parcours. Ces hypnoses ont été conçues spécialement pour les parcours de PMA et peuvent être écoutées plusieurs fois dans la journée, selon vos besoins."}
                    </p>
                    <div className={"carouselContainer"}>
                        {Hypnose.map((content, index) => (
                            <div key={index} className={"carouselItem"}>
                                <div className={"card"}>
                                    <h3>{`${content.title}`}</h3>
                                    <a href={content.uri} target="_blank" rel="noopener noreferrer">
                                        <img src={content.preview} alt={content.title} className={"videoImage"}/>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default PrendreSoinDeMoi;
