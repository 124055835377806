import './Temoignage.css';
import React from 'react';

const Presentation = (props) => {
    const { updatePos } = props;

    return (
        <div id="temoignage" onMouseEnter={() => updatePos('temoignage')} className='section' >
            <div className='generalBlock' style={{ minHeight: 475, paddingBottom: 75 }}>
                <div className='titreGlobal textViolet titreTemoignage' id="temoignageText">
                    Vos témoignages
                </div>
                <div className='temoignageFlexBox' >
                    <div className='temoignageImageWrapper'>
                        <img className='temoignageImage' src={process.env.PUBLIC_URL + '/assets/temoignage-1-2.webp'} alt={"temoignage1"} />
                    </div>
                    <div className='temoignageImageWrapper'>
                        <img className='temoignageImage' src={process.env.PUBLIC_URL + '/assets/temoignage-2-2.webp'} alt={"temoignage2"} />
                    </div>
                    <div className='temoignageImageWrapper'>
                        <img className='temoignageImage' src={process.env.PUBLIC_URL + '/assets/temoignage-3-2.webp'} alt={"temoignage3"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Presentation;
