import './Politique.css';
import React from 'react';

const Politique = (props) => {
    const { isMobile } = props;
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='generalBlock' id='pol-conf'>
            <div className='politiqueBlock'>
                <div className='titreGlobal textViolet titrePolitique'>
                    POLITIQUE DE CONFIDENTIALITÉ
                </div>
                <div>
                    <p>
                        Eugénie Vellieux pour HoliYou (ci-après « HoliYou », « nous »), Rue de Langallerie 7,
                        1003 Lausanne est la responsable du traitement de vos données&nbsp;personnelles collectées dans le cadre de cette notice de confidentialité.
                    </p>
                    <p>
                        Pour toute question afférente à vos données&nbsp;personnelles,
                        vous pouvez nous contacter en tout temps par e-mail à <a href='mailto:privacy@holiyou.life'>privacy@holiyou.life</a> et au numéro de téléphone suivant : {isMobile?<a href='tel:+41787311903'>+41&nbsp;78&nbsp;7311903</a>:<span>+41&nbsp;78&nbsp;7311903</span>}.
                    </p>
                    <p>
                        Nous respectons votre vie privée et nous nous engageons à une transparence totale quant à la manière dont nous collectons,
                        utilisons et partageons vos données&nbsp;personnelles, en confirmité avec la Loi fédérale sur la protection des données (LPD) du 25 septembre 2020,
                        ainsi qu’avec le Règlement Général sur la Protection des Données ou RGPD (Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016).
                    </p>
                    <p>
                        La présente notice de confidentialité vous informe comment nous traitons les données&nbsp;personnelles que nous recueillons auprès de vous,
                        que vous nous fournissez ou que nous pouvons recueillir auprès d’autres sources.
                        Cela comprend les différentes façons dont vous interagissez avec nous, notamment via notre site web, notre Application, en personne, par e-mail ou par téléphone.
                    </p>
                    <p>
                        Cette notice de confidentialité a pour objectif :
                    </p>
                    <ul className='ulPersonnelle'>
                        <li>
                            de lister les données&nbsp;personnelles que nous collectons auprès de vous ;
                        </li>
                        <li>
                            d’expliquer la finalité du traitement de ces données et de préciser sur quelles bases légales nous traitons ces données ;
                        </li>
                        <li>
                            d’identifier avec qui ces données sont partagées et si elles sont transférées à l’étranger;
                        </li>
                        <li>
                            d’énoncer les mesures de sécurité que nous prenons en lien avec ces données ;
                        </li>
                        <li>
                            de vous informer de vos droits en ce qui concerne vos données&nbsp;personnelles et de la manière dont vous pouvez les exercer
                        </li>
                    </ul>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    NOTRE NOTICE DE CONFIDENTIALITÉ
                </div>
                <div>
                    <p>
                        En interagissant avec nous ou en nous communiquant vos données&nbsp;personnelles, vous acceptez les règles décrites dans cette notice de confidentialité.
                        Si vous n’acceptez pas ces règles, veuillez ne pas utiliser l’Application ou visiter notre site web ni nous fournir vos données&nbsp;personnelles.
                    </p>
                    <p>
                        HoliYou se réserve le droit d’apporter des modifications à cette notice de confidentialité,
                        qui seront effectives dès leur publication sur notre site web. Veillez à consulter notre site web régulièrement pour tout changement.
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    QUELLES DONNÉES&nbsp;PERSONNELLES COLLECTONS-NOUS ?
                </div>
                <div>
                    <p>
                        Lorsque vous interagissez avec nous, par exemple via notre site web, notre Application, nos comptes de médias sociaux,
                        par e-mail ou par téléphone, lorsque vous visitez nos locaux physiques ou lors d'événements, nous pouvons être amenés à collecter vos données&nbsp;personnelles.
                    </p>
                    <p>
                        Nous ne collectons que les données&nbsp;personnelles que vous nous communiquez, qui résultent de vos actions sur notre site web
                        et notre Application et/ou dont nous avons besoin pour vous offrir ou améliorer nos services, ou encore sur la base d’un autre intérêt légitime :
                    </p>
                    <ul className='ulPersonnelle'>
                        <li>
                            <span>Informations de contact et d’identification</span> telles que nom,
                            prénom, adresse physique et électronique et des métadonnées de communication telles que votre adresse IP, la signature temporelle et la géolocalisation;
                        </li>
                        <li>
                            <span>Informations d’identification</span>, telles que nom d’utilisateur, ID du compte, ainsi qu’<span>informations d’authentification</span>,
                            telles que mots de passe et réponses aux questions de sécurité ;
                        </li>
                        <li>
                            <span>Informations relatives aux caractéristiques personnelles</span>, telles que sexe, âge, poids et taille ;
                        </li>
                        <li>
                            <span>Informations relatives à la situation familiale</span>, telles que célibataire, en couple, marié/e, pacsé/e ;
                        </li>
                        <li>
                            <span>Informations relatives à la sexualité</span>, telles qu’héterosexuel/le, LGBTQIA+ ;
                        </li>
                        <li>
                            <span>Informations relatives à la santé et à la fertilité</span>, telles que prise de médicaments, antécédents médicaux et obstéticraux,
                            suivi de fertilité et de cycle, affiliation à hôpital ou centre de fertilité ;
                        </li>
                        <li>
                            <span>Informations transactionnelles</span>, telles que les détails de paiement
                            (par exemple, les numéros de carte de crédit ou de compte bancaire) et l'adresse de facturation ;
                        </li>
                        <li>
                            <span>Informations issues de vos comptes de médias sociaux</span>, telles que profils, photos, vidéos, stories, commentaires
                            et autres contenus générés par vous où nos marques et/ou services sont mentionnés ou apparaissent, lorsque vous autorisez un réseau social tiers
                            à partager vos données&nbsp;personnelles avec nous ou lorsque vous utilisez une fonctionnalité d’un réseau social qui est intégrée à notre site web ;
                        </li>
                        <li>
                            <span>Informations professionnelles</span>, si vous répondez à une offre d’emploi ou envoyez une candidature spontanée, telles que les expériences professionnelles précédentes,
                            les établissements éducatifs fréquentés, les références ou les certifications ;
                        </li>
                    </ul>
                    <p>
                        Nous collectons ces données directement auprès de vous, notamment lorsque vous les renseignez dans notre Application.
                    </p>
                    <p>
                        Nous utilisons aussi des cookies et autres technologies de suivi (par ex. pixels, balises web et APIs) qui collectent certains types
                        d'informations lorsque vous interagissez avec nous sur notre site web ou via nos pages de média sociaux, tels que l’adresses IP, la localisation,
                        les appareils ou préférences de navigation, le système d'exploitation,
                        la source, la durée de la visite, les pages consultées, etc. Pour de plus amples renseignements, veuillez consulter notre notice sur les cookies.
                    </p>
                    <p>
                        Nous pouvons également recueillir des données&nbsp;personnelles provenant notamment d’agrégateurs de données de tiers (par ex. Google), partenaires promotionnels,
                        sources publiques et plateformes de réseaux sociaux. Ces informations peuvent inclure des données&nbsp;personnelles de votre profil sur un réseau social
                        et dont vous autorisez ce réseau à nous communiquer conformément à leurs règles.
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    POURQUOI COLLECTONS-NOUS VOS DONNÉES&nbsp;PERSONNELLES ET SUR QUELLES BASES LÉGALES ?
                </div>
                <div>
                    <p>
                        Nous collectons et utilisons vos données&nbsp;personnelles afin :
                    </p>
                    <ul className='ulPersonnelle'>
                        <li>
                            de vous fournir nos services au travers de notre Application,
                            plus particulièrement de vous entourer et de vous accompagner en parcours de PMA, incluant l’utilisation de notre outil prédictif ;
                        </li>
                        <li>
                            d’effectuer des transactions commerciales lors du téléchargement de notre Application;
                        </li>
                        <li>
                            de vous offrir des services de marketing et de communication pertinents en lien avec vos besoins et préférences ;
                        </li>
                        <li>
                            d’optimiser votre expérience d’utilisateur/trice sur notre site web et notre Application ;
                        </li>
                        <li>
                            de garantir la sécurité et la maintenance de notre site web et de notre Application ;
                        </li>
                        <li>
                            de répondre à des demandes d'emploi, non sollicitées ou autres ;
                        </li>
                        <li>
                            de conserver des traces de nos activités afin de nous conformer à nos obligations financières ou juridiques.
                        </li>
                    </ul>
                    <p>
                        Nous traitons vos données sur une base précontractuelle et/ou contractuelle lors de toutes vos interactions avec HoliYou liées à la création d’un compte
                        et à l’utilisation de notre Application, incluant vos prises de contact préalables avec nous ou vos demandes de renseignement,
                        ainsi que toutes transactions commerciales y attenantes.
                        Nous traiterons vos données&nbsp;personnelles sur la même base si vous répondez à une offre d'emploi ou si vous nous envoyez une candidature spontanée.
                    </p>
                    <p>
                        Pour les données sensibles relatives à la sexualité et à la santé/fertilité, nous vous demanderons en outre votre consentement explicite au traitement effectué,
                        au moyen d’une case à cocher dans l’Application.
                        Veuillez noter que vous pouvez exercer l'option de retirer votre consentement à tout moment en nous contactant à <a href='mailto:privacy@holiyou.life'>privacy@holiyou.life</a>.
                        Le retrait du consentement ne compromet toutefois pas la liceité du traitement effectué avant ce retrait.
                    </p>
                    <p>
                        Vous pouvez également vous abonner à nos communications et à nos supports marketing, tels que notre Newsletter ou notre liste de mailing.
                        Dans ce cas nous vous demanderons également votre consentement pour traiter vos données&nbsp;personnelles.
                        Veuillez noter que vous pouvez exercer l'option de vous désabonner ou de retirer votre consentement à tout moment en utilisant l’option “se désabonner”
                        ou en nous contactant à <a href='mailto:privacy@holiyou.life'>privacy@holiyou.life</a>.
                        Pour les questions de consentement liées aux cookies, veuillez consulter notre notice sur les cookies.
                    </p>
                    <p>
                        Il est en outre dans notre intérêt légitime de traiter certaines de vos informations pour garantir la sécurité de notre site web et de notre Application.
                        Veuillez noter que dans ce cas également, vous pourrez objecter au traitement en nous contactant à <a href='mailto:privacy@holiyou.life'>privacy@holiyou.life</a>.
                    </p>
                    <p>
                        Enfin, nous avons des obligations légales en vertu du droit national et international.
                        Afin de remplir nos obligations, nous traitons vos données&nbsp;personnelles à des fins de conformité, afin de nous conformer à nos obligations financières ou juridiques.
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    AVEC QUI PARTAGEONS-NOUS VOS DONNÉES&nbsp;PERSONNELLES ET SONT-ELLES TRANSFÉRÉES À&nbsp;L’ÉTRANGER ?
                </div>
                <div>
                    <p>
                        Nous ne vendons pas vos données&nbsp;personnelles.
                    </p>
                    <p>
                        Les données&nbsp;personnelles des utilisateurs/trices de notre Application sont en général hébergées dans leur pays de résidence.
                        Il se peut que nous transférions vos données&nbsp;personnelles à certaines occasions limitées,
                        mais, dans ces cas de figure, les transferts se font uniquement entre la Suisse et la Union Européenne (plus particulièrement la France et l’Espagne).
                    </p>
                    <p>
                        L’hôpital ou le centre de fertilité auquel vous êtes affilié/e et que vous avez renseigné dans l’Application aura accès à vos données&nbsp;personnelles.
                    </p>
                    <p>
                        Il est également possible que nous partagions quelques unes de vos données&nbsp;personnelles avec certains de nos fournisseurs de services (par ex. le centre d’appel
                        et le service de paiement par carte de crédit, notre fiduciaire),
                        avec des partenaires commerciaux ou des agences de marketing et de communication (par ex. services d’édition et de distribution de la Newsletter), ou avec des autorités.
                    </p>
                    <p>
                        Nous exigeons de ces tiers de respecter toutes les lois relatives aux données&nbsp;personnelles.
                    </p>
                    <p>
                        Il se peut aussi que nous utilisions des plugins sur notre site web, renvoyant à des plateformes de médias sociaux.
                        L'utilisation par la plateforme tierce des informations recueillies auprès de vous (ou telles qu'autorisées par vous) est régie
                        par sa propre notice de confidentialité et vos paramètres sur le service. Si vous ne souhaitez pas qu’un site de réseau social tiers
                        attribue les données collectées par l'intermédiaire de notre site web à votre compte sur ce réseau social,
                        vous devez vous déconnecter du site du réseau social concerné avant de visiter notre site web.
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    COMMENT VOS DONNÉES SONT-ELLES STOCKÉES, SÉCURISÉES ET CONSERVÉES ?
                </div>
                <div>
                    <p>
                        Nous prenons toutes les précautions techniques et organisationnelles raisonnables pour sécuriser vos données&nbsp;personnelles contre toute perte,
                        accès non-autorisé et autre violation.
                        Ces données ne seront pas partagées avec des tiers, sauf dans les cas prévus dans cette notice de confidentialité.
                    </p>
                    <p>
                        Lorsque les informations personnelles que nous recueillons ne sont plus requises pour une finalité donnée
                        ou sur la base d’une exigence légale, nous les détruisons ou les anonymisons.
                    </p>
                    <p>
                        Nous nous engageons également à ne pas conserver vos données&nbsp;personnelles si vous deviez
                        ne montrer aucun intérêt pour nos services pendant une période définie après la fin de votre dernière prise de contact avec nous.
                    </p>
                </div>

                <div className='textViolet sousTitrePolitique'>
                    VOS DROITS - COMMENT ACCÉDER À VOS DONNÉES&nbsp;PERSONNELLES ET DEMANDER LEUR RECTIFICATION
                </div>
                <div>
                    <p>
                        Vous pouvez exercer vos droits en nous contactant simplement à <a href='mailto:privacy@holiyou.life'>privacy@holiyou.life</a>.
                    </p>
                    <p>
                        Vous pouvez demander l’accès à vos données&nbsp;personnelles, ainsi que les corriger ou les mettre à jour.
                    </p>
                    <p>
                        Si le traitement est basé sur votre consentement, vous avez le droit de le retirer en tout temps.
                        Vous pouvez d’ailleurs vous désabonner de notre Newsletter pour ne plus recevoir de communications marketing de notre part
                        en utilisant l’option “se désabonner” ou en nous contactant à <a href='mailto:privacy@holiyou.life'>privacy@holiyou.life</a>.
                    </p>
                    <p>
                        Si le traitement est basé sur l’intérêt légitime, vous avez le droit d’objecter au traitement.
                    </p>
                    <p>
                        Pour nous aider à traiter votre demande, veuillez nous fournir vos coordonnées complètes, afin que nous puissions vous identifier de manière sécure.
                    </p>
                    <p>
                        Vous pouvez également limiter l'utilisation des technologies de suivi, tels que les cookies,
                        en configurant votre navigateur pour qu'il vous avertisse avant de les accepter, ou vous pouvez simplement le configurer pour les refuser.
                    </p>
                    <p>
                        Dans le cas où vous estimeriez que nous ne respectons pas vos droits ou nos obligations,
                        vous pourrez directement effectuer une réclamation auprès des autorités de protection des données en Suisse.
                    </p>
                    <p>
                        La notice de confidentialité et toutes questions en découlant ou liées à celle-ci sont régies par le droit suisse.
                        Vous acceptez la compétence exclusive des tribunaux du siège de HoliYou.
                    </p>
                </div>
                <div className='politiqueHoliYou'>
                    HoliYou - Août 2023
                </div>
            </div>
        </div>
    )
}

export default Politique;