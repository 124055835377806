import React from 'react';
import "./Contactez.css"

const Newsletter = () => {

    return (
        <div id="contact">
            <div className='generalBlock contactBlock'>
                <div className='contactWrap'>
                    <div className='titleContact' id="contactText">Contactez-nous</div>
                    <div style={{ marginTop: 20 }}>
                        <a className='linkContact' href='mailto:bonjour@holiyou.life'>bonjour@holiyou.life</a>
                    </div>
                </div>
                <div className='contactWrap bottomWrapContact'>
                    <div className='titleContact' >Suivez-nous</div>
                    <div style={{ marginTop: 20 }}>
                        <div style={{ display: "inline-block", width: "8%", marginRight: '2%' }}>
                            <a href='https://www.linkedin.com/company/89638267/admin/' target={"_blank"} rel="noopener noreferrer">
                                <img style={{ width: "100%" }} alt='Linkedin' src={process.env.PUBLIC_URL + '/assets/linkedin.webp'} />
                            </a>
                        </div>
                        <div style={{ display: "inline-block", width: "8%" }}>
                            <a href='https://www.instagram.com/holiyou.fertilite/' target={"_blank"} rel="noopener noreferrer">
                                <img style={{ width: "100%" }} alt='Instagram' src={process.env.PUBLIC_URL + '/assets/instagram.webp'} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newsletter;
