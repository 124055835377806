import React from 'react';
import PropTypes from 'prop-types';
import {PrendreSoinDeMoiTextes} from "../constant/constants";
import './RecommendedSection.css'
import {Meditation} from "../constant/meditation";
import {Yoga} from "../constant/yoga";
import {Hypnose} from "../constant/hypnose";

const VideoGrid = ({ videos }) => {
    return (
        <div className={"videoGrid"}>
            {videos.map((video, index) => (
                <div key={index} className={"videoItem"}>
                    <div className={"videoCard"}>
                        <h4>{video.title}</h4>
                        <a href={video.uri} target="_blank" rel="noopener noreferrer">
                            <img src={video.preview} alt={video.title} className={"videoImage"}/>
                        </a>
                    </div>
                </div>
            ))}
        </div>
    );
};

VideoGrid.propTypes = {
    videos: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired,
        preview: PropTypes.string.isRequired,
    })).isRequired,
};

const RecommendedSection = ({ userData }) => {
    const videoCategories = {
        mediation: Meditation.filter((video) => video.phase === userData.phase),
        yoga: Yoga.filter((video) => video.phase === userData.phase),
        hypnose: Hypnose.filter((video) => video.phase === userData.phase),
    };

    return (
        <section id="recommended" className={"recommendedSection"}>
            <h2 className={"sectionTitle"}>{`Recommandé pendant votre phase de ${userData.phase}`}</h2>
            <p className={"sectionDescription"}>
                {PrendreSoinDeMoiTextes.find((text) => text.phase.includes(userData.phase))?.content || ""}
            </p>
            {Object.keys(videoCategories).map((categoryKey) => (
                <div key={categoryKey} className={"category"}>
                    <VideoGrid videos={videoCategories[categoryKey]} />
                </div>
            ))}
        </section>
    );
};

RecommendedSection.propTypes = {
    userData: PropTypes.shape({
        phase: PropTypes.string.isRequired,
    }).isRequired,
};

export default RecommendedSection;
