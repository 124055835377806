import './Solution.css';
import React from 'react';

import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { config } from "react-spring";

const Solution = (props) => {
    const { updatePos } = props;
    const [goTo, setGoTo] = React.useState(0);
    const [xDown, setXDown] = React.useState(null)
    const [yDown, setYDown] = React.useState(null)

    const slides = [
        {
            key: uuidv4(),
            content: <img alt='Capture 1' src={process.env.PUBLIC_URL + '/assets/screens/carousel-1.webp'} />
        },
        {
            key: uuidv4(),
            content: <img alt='Capture 2' src={process.env.PUBLIC_URL + '/assets/screens/carousel-2.webp'} />
        },
        {
            key: uuidv4(),
            content: <img alt='Capture 3' src={process.env.PUBLIC_URL + '/assets/screens/carousel-3.webp'} />
        },
        {
            key: uuidv4(),
            content: <img alt='Capture 4' src={process.env.PUBLIC_URL + '/assets/screens/carousel-4.webp'} />
        },
    ]

    const getTouches = (evt) => {
        return (
            evt.touches || evt.originalEvent.touches // browser API
        );
    };

    const handleTouchStart = (evt) => {
        const firstTouch = getTouches(evt)[0];
        setXDown(firstTouch.clientX);
        setYDown(firstTouch.clientY)
    };

    const handleTouchMove = (evt) => {
        if ((!xDown && !yDown)) {
            return;
        }

        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;

        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                /* left swipe */
                setGoTo(goTo + 1);
                setXDown(null);
                setYDown(null);
            } else {
                /* right swipe */
                setGoTo(goTo - 1);
                setXDown(null);
                setYDown(null);
            }
        }
    };

    const Move = (i) => {
        if (i >= 0 && i < slides.length) {
            setGoTo(i);
        }
        else if (i === slides.length) {
            setGoTo(0);
        }
        else {
            setGoTo(slides.length - 1)
        }
    }

    return (
        <div id='solution' onMouseEnter={() => updatePos("solution")} className='section' >
            <div className='generalBlock solutionBlock' >
                <div className='titreGlobal textViolet titreSolution' id="solutionText">
                    Nos solutions
                </div>
                <div className='middleText solutionMiddleText'>
                    L'application s'adresse à tout.e.s celles et ceux qui souhaitent un accompagnement personnalisé dans leurs parcours
                    de PMA.
                    Nous vous mettons à disposition des outils sur-mesure, adaptés à votre situation personnelle, qui améliorent votre
                    quotidien et soulagent vos maux.
                </div>
                <div className='CarouselWrapper'>
                    <div className='ImageCarouselHeight' style={{ width: "10%", display: "inline-block", float: "left", position: 'relative' }}>
                        <div onClick={() => Move(goTo - 1)} className='verticalCenter cliquable arrowSolutionR'>
                            <img alt='Précédent' src={process.env.PUBLIC_URL + '/assets/flèche-gauche.webp'} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div
                     className='ImageCarouselHeight'
                        style={{ width: "80%", margin: "0 auto", display: "inline-block" }}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                    >
                        <Carousel
                            slides={slides}
                            goToSlide={goTo}
                            animationConfig={config.gentle}
                            showNavigation={false}
                            offsetRadius={2}
                        />
                    </div>
                    <div  className='ImageCarouselHeight' style={{ width: "10%", display: "inline-block", float: "right", position: 'relative' }}>
                        <div onClick={() => Move(goTo + 1)} className='verticalCenter cliquable arrowSolutionL'>
                            <img alt='Suivant' src={process.env.PUBLIC_URL + '/assets/flèche-droite.webp'} style={{ width: "100%" }} />
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ width: 40 * slides.length, height: 50, margin: "0 auto" }}>
                        {
                            slides.map((v, i) => {
                                return (
                                    <div key={'bullet-' + v.key} style={{ width: 40 , display:'inline-block'}}>
                                        <div className='cliquable' onClick={() => Move(i)} style={{ width: 30, margin: "0 auto" }}>
                                            <img alt={"point " + i} src={goTo === i? process.env.PUBLIC_URL + '/assets/bullet-pleine.webp': process.env.PUBLIC_URL + '/assets/bullet-creuse.webp'} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Solution;
