import './Mission.css';
import React from 'react';

const Mission = (props) => {
    const { updatePos } = props;

    return (
        <div onMouseEnter={() => updatePos("mission")} id="mission" className='section missionSection'>
            <div className='generalBlock' style={{paddingBottom:30}}>
                <div className='titreGlobal textViolet titreMission' id="missionText">
                    Notre mission
                </div>
                <div className='middleText'>
                    Un parcours de PMA, quel qu'il soit, est un bouleversement psychique et physique. Notre volonté est celle
                    d'entourer et d'accompagner les patient.e.s en parcours, de leurs donner des outils pour mieux
                    appréhender leurs protocoles et de proposer des solutions holistiques pour améliorer le bien-être général,
                    la santé physique et mentale.
                </div>
                <div className='missionBottomText'>
                    HoliYou est la première solution self-care dédiée aux parcours de PMA, développée en collaboration
                    avec des centres de fertilité de renoms en Europe.
                </div>
                <div>
                    <div className='imageMissionWrap'>
                        <div className='imageMission'>
                            <img style={{ width: "100%" }} alt='Réumaniser les parcours de PMA' src={process.env.PUBLIC_URL + '/assets/réhumaniser.webp'} />
                        </div>
                    </div>
                    <div className='imageMissionWrap'>
                        <div className='imageMission'>
                            <img style={{ width: "100%" }} alt='Personnaliser le suivit et les outils à disposition'
                                src={process.env.PUBLIC_URL + '/assets/personnaliser.webp'} />
                        </div>
                    </div>
                    <div className='imageMissionWrap'>
                        <div className='imageMission'>
                            <img style={{ width: "100%" }} alt='Améliorer le bien être général' src={process.env.PUBLIC_URL + '/assets/améliorer.webp'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mission;
